import * as React from "react";
import Typography from "@mui/material/Typography";
import AccordionStyled from "components/Accordion/AccordionStyled";
import AccordionDetailsStyled from "components/Accordion/AccordionDetailsStyled";
import AccordionSummaryStyled from "components/Accordion/AccordionSummaryStyled";
import FormSection from "./FormSection/FormSection";
import StepNumber from "components/StepNumber/StepNumber";
import { createStyles, makeStyles } from "@mui/styles";
import { Box, Paper, Theme } from "@mui/material";
import { useTheme } from "@mui/styles";
import CalculationSection from "./CalculationSection/CalculationSection";
import AppContext from "AppContext";

import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    stepLabelWrap: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  })
);
export enum ActiveTab {
  form = "form",
  calculation = "calculation",
}

const MainForm = () => {
  const [expanded, setExpanded] = React.useState<string | false>(
    ActiveTab.form
  );
  const { formSubmited, isSuccess } = React.useContext(AppContext);
  const theme = useTheme() as any;
  const { t } = useTranslation();
  const classes = useStyles();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const mainColor = theme.palette.primary.main;

  const Success = () => {
    return (
      <Paper elevation={3}>
        <Box margin={10} sx={{ fontWeight: 600 }}>
          {t("ccc.successMessage.label")}
        </Box>
      </Paper>
    );
  };

  const MainBox = () => {
    return (
      <Paper elevation={3}>
        <AccordionStyled
          expanded={expanded === ActiveTab.form}
          onChange={handleChange(ActiveTab.form)}
          TransitionProps={{
            timeout: 0,
          }}
        >
          <AccordionSummaryStyled
            aria-controls="etikett1d-content"
            id="etikett1d-header"
            color={mainColor}
          >
            <Typography className={classes.stepLabelWrap} color={mainColor}>
              <StepNumber stepSign={1} />
              {t("ccc.basicData.label")}
            </Typography>
          </AccordionSummaryStyled>
          <AccordionDetailsStyled>
            <FormSection setExpanded={setExpanded} />
          </AccordionDetailsStyled>
        </AccordionStyled>
        <AccordionStyled
          TransitionProps={{
            timeout: 0,
          }}
          expanded={expanded === ActiveTab.calculation}
          onChange={handleChange(ActiveTab.calculation)}
          color={mainColor}
          disabled={expanded !== ActiveTab.calculation && !formSubmited}
        >
          <AccordionSummaryStyled
            aria-controls="etikett2d-content"
            id="etikett2d-header"
          >
            <Typography className={classes.stepLabelWrap} color={mainColor}>
              <StepNumber stepSign={2} />
              {t("ccc.evaluation.label")}
            </Typography>
          </AccordionSummaryStyled>
          <AccordionDetailsStyled>
            <CalculationSection />
          </AccordionDetailsStyled>
        </AccordionStyled>
      </Paper>
    );
  };

  return isSuccess ? Success() : MainBox();
};

export default MainForm;
