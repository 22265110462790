import { Grid } from '@mui/material'
import { Box } from '@mui/system'
import TextInputField from 'components/controls/TextInputField'
import GridContainer from 'components/form/GridContainer/GridContainer'
import GridItem from 'components/form/GridItem/GridItem'
import GridWrap from 'components/form/GridWrap/GridWrap'
import { FormikProps, FormikValues, useFormikContext } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'

const InfoFormItems = () => {
  const {
    values,
    handleChange,
    errors,
    setFieldValue,
  } = useFormikContext() as FormikProps<FormikValues>
  const { t } = useTranslation()
  return (
    <GridWrap>
      <GridContainer>
        <GridItem>
          <TextInputField
            value={values.EmployeeName}
            onChange={handleChange}
            errors={errors}
            required
            name={'EmployeeName'}
            disabled={false}
            label={t('ccc.employeeName.label')}
          />
        </GridItem>
        <GridItem>
          <TextInputField
            value={values.CompanyName}
            onChange={handleChange}
            errors={errors}
            required
            name={'CompanyName'}
            disabled={false}
            label={t('ccc.companyName.label')}
          />
        </GridItem>
        <GridItem>
          <TextInputField
            value={values.EmployeePosition}
            onChange={handleChange}
            errors={errors}
            // required
            name={'EmployeePosition'}
            disabled={false}
            label={t('ccc.employeePosition.label')}
          />
        </GridItem>
        <GridItem>
          <TextInputField
            value={values.EmployeeEmailAddress}
            onChange={handleChange}
            errors={errors}
            required
            name={'EmployeeEmailAddress'}
            disabled={false}
            label={t('ccc.employeeEmailAddress.label')}
          />
        </GridItem>
      </GridContainer>
    </GridWrap>
  )
}

export default InfoFormItems
