import React from "react";

const ToastContext = React.createContext({
  state: {},
  setToastState: ({}) => {
    throw new Error("setToastState() not implemented");
  },
});

export default ToastContext;
