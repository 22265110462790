import { Box } from '@mui/material'
import FormTitle from 'components/form/FormTitle/FormTitle'
import SectionWrap from 'components/form/SectionWrap/SectionWrap'
import React from 'react'
import { useTranslation } from 'react-i18next'
import QuestionsFormItems from './QuestionsFormItems'

const Questions = () => {
  const { t } = useTranslation()
  return (
    <SectionWrap>
      <FormTitle>{t('ccc.cyberSecurityRequirements.label')}</FormTitle>
      <QuestionsFormItems />
    </SectionWrap>
  )
}

export default Questions
