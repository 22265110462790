import * as React from "react";
import "./UserSettings.scss"
// --- Api
// import { logout } from "../../../../auth/AuthMSAL"
// --- Fabric UI
import { Persona, PersonaSize, Link } from 'office-ui-fabric-react';
import { IUserSettingsStrings } from "./IUserSettingsStrings";


interface IProps {
  userData: any
  logout: any
  strings?: IUserSettingsStrings
}


export class UserSettings extends React.PureComponent<IProps,{}> {

  public render(): JSX.Element {
    return (
      <div className="ms-u-slideLeftIn400">
        <div className="user-settings">
          <div className="user-settings__image">
            <Persona
              {...{
                text: this.props.userData.displayName,
                imageUrl: this.props.userData.photoMedium
              }}
              hidePersonaDetails={true}
              size={PersonaSize.size100}
            />
          </div>
          <div className="user-settings__details">
            <div className="name">{this.props.userData.displayName}</div>
            <div className="email">{this.props.userData.mail}</div>
            <div className="links">
              <Link href="https://delve.office.com/" target="_blank">
                {this.props.strings && this.props.strings.myOfficeProfileLinkText || "My Office profile"}
              </Link>
              <Link
                href="https://portal.office.com/account/"
                target="_blank"
              >
                {this.props.strings && this.props.strings.myAccountLinkText || "My account"}
              </Link>
              <Link onClick={() => this.props.logout()}>{this.props.strings && this.props.strings.signOutLinkText || "Sign out"}</Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}