import React, { Suspense } from "react";
import { StylesProvider, createGenerateClassName } from "@mui/styles";
import { ThemeProvider } from "@mui/material/styles";
import AppProvider from "AppProvider";
import Spinner from "components/Spinner/Spinner";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import CalculatorPage from "pages/CalculatorPage";
import LandingPage from "pages/LandingPage";
import PreviewPage from "pages/PreviewPage";
import { theme } from "theme/theme";
import { CssBaseline } from "@mui/material";
import ToastProvider from "components/Toast/ToastProvider";

function App() {
  const generateClassName = createGenerateClassName({
    productionPrefix: "c",
  });

  return (
    <Suspense fallback={<Spinner />}>
      <CssBaseline />
      <AppProvider>
        <ThemeProvider theme={theme}>
          <StylesProvider generateClassName={generateClassName}>
            <ToastProvider>
              <Router>
                <Routes>
                  <Route path="/calculation/:id" element={<PreviewPage />} />
                  <Route path="/calculator" element={<CalculatorPage />} />
                  <Route path="/" element={<LandingPage />} />
                  {/* <Route path="*" element={<Navigate to="/" />} /> */}
                  <Route path="*" element={<LandingPage />} />
                </Routes>
              </Router>
            </ToastProvider>
          </StylesProvider>
        </ThemeProvider>
      </AppProvider>
    </Suspense>
  );
}

export default App;
