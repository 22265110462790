import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js'
import { Bar, Pie } from 'react-chartjs-2'
import { getRiskChartLabels, riskChartOptions } from './levelOfRiskConfig'
import { IRiskData } from 'library/utils/types'
import { Box } from '@mui/system'
import ChartDataLabels from 'chartjs-plugin-datalabels'

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
)

type Props = {
  riskData: IRiskData
}
const RiskChart = ({ riskData }: Props) => {
  const isMobile = window.innerWidth < 500

  if (!riskData) return

  const {
    predictedDamageCrisisManagement,
    predictedDamageBusinessInterruption,
    predictedDamageRecovery,
    predictedDamageRansom,
    predictedDamageAll,
  } = riskData

  const options = riskChartOptions

  const labels = getRiskChartLabels({
    predictedDamageCrisisManagement,
    predictedDamageBusinessInterruption,
    predictedDamageRecovery,
    predictedDamageRansom,
    predictedDamageAll,
  })

  const data = {
    labels: labels.map((item) => item.label),
    datasets: [
      {
        label: '',
        data: labels.map((item) => item.value),
        borderColor: labels.map((item) => item.borderColor),
        backgroundColor: labels.map((item) => item.backgroundColor),
        datalabels: {
          color: 'black',
          font: {
            size: 14,
            weight: 600,
          },
        },
      },
    ],
  }

  // const data = {
  //   labels: [''],

  //   datasets: labels.map((item) => ({
  //     label: '',
  //     data: [item.value],
  //     backgroundColor: item.backgroundColor,
  //     datalabels: {
  //       color: 'black',
  //       font: {
  //         size: 14,
  //         weight: 600,
  //       },
  //     },
  //   })),
  // }
  // return <Bar options={options} data={data} height={120} />;
  return (
    <Box
      // sx={{
      //   width: { xs: '235px', lg: '248px' },
      //   margin: { xs: '10px auto 0px', lg: '2px 20px 20px 40px' },
      // }}
      width={'100%'}
    >
      <Bar data={data} options={options} height={isMobile ? 200 : 140} />
    </Box>
  )
}

export default RiskChart
