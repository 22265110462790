import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { Box } from "@mui/system";

import { apiService } from "services/api";
import AppContext from "AppContext";
import ToastContext from "components/Toast/ToastContext";
import { ToastType } from "library/utils/types";
import LoadingModal from "components/LoadingModal/LoadingModal";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";

const ConfirmAndCreateOffer = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { calculationData, setIsSuccess, state } = useContext(AppContext);
  const { setToastState } = useContext(ToastContext);

  const { t } = useTranslation();
  const calculationId = calculationData?.calculationId;

  const handleSendInquiry = async () => {
    setLoading(true);
    try {
      const response = await apiService.setOfferRequest(calculationId);
      if (response) {
        setToastState({
          open: true,
          message: t("ccc.inquirySuccessfullySent"),
          severity: ToastType.SUCCESS,
        });
        if (state && state.SellerName === "Iizi") {
          setIsSuccess(true);
        } else {
          navigate("/");
        }
      }
      return response;
    } catch (error) {
      const message =
        error?.response?.data?.messageCode ||
        error?.response?.data[0]?.messageCode;
      setToastState({
        open: true,
        message,
        severity: ToastType.ERROR,
      });
      setLoading(false);

      return false;
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <LoadingModal open={loading} />
      <Box marginTop={5} sx={{ fontWeight: 600 }}>
        {t("ccc.finalSendOff.label")}
      </Box>
      <Box marginTop={4} display="flex" justifyContent="end">
        <LoadingButton
          disabled={loading}
          loading={loading}
          autoFocus
          onClick={handleSendInquiry}
          variant="contained"
          sx={{
            minWidth: 150,
          }}
        >
          {t("ccc.sendInquiry")}
        </LoadingButton>
      </Box>
    </>
  );
};

export default ConfirmAndCreateOffer;
