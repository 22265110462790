export const statusTableRows = (t) => {
  return [
    {
      title: t("ccc.securityHighLevelStatus.label"),
      label: "10",
      values: [10],
    },
    {
      title: t("ccc.securityMediumLevelStatus.label"),
      label: `7 - 9`,
      values: [7, 8, 9],
    },
    {
      title: t("ccc.securityBasicLevelStatus.label"),
      label: `4 - 6`,
      values: [4, 5, 6],
    },
    {
      title: t("ccc.securiyLowLevelStatus.label"),
      label: `0 - 3`,
      values: [0, 1, 2, 3],
    },
  ];
};
