import FormLayout from "components/Layout.tsx/FormLayout";
import MainForm from "components/Main/MainForm";

import React from "react";

const CalculatorPage = () => {
  return (
    <FormLayout>
      <MainForm />
    </FormLayout>
  );
};

export default CalculatorPage;
