import { Typography } from "@mui/material";
import { t } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";

const Title = () => {
  const { t } = useTranslation();
  return (
    <Typography sx={{ typography: { sm: "h4", xs: "h5" } }}>
      {t("ccc.cyberAwerenessTool.label")}
    </Typography>
  );
};

export default Title;
