import { Box } from "@mui/material";

import FormTitle from "components/form/FormTitle/FormTitle";
import SectionWrap from "components/form/SectionWrap/SectionWrap";
import React from "react";
import { useTranslation } from "react-i18next";
import InfoFormItems from "./InfoFormItems";

const Info = () => {
  const { t } = useTranslation();
  return (
    <SectionWrap>
      <FormTitle>{t("ccc.info.label")}</FormTitle>
      <InfoFormItems />
    </SectionWrap>
  );
};

export default Info;
