export const questionsConfig = [
  {
    name: "ItSecurityAdminRights",
    label: "ccc.ItSecurityAdminRights.label",
  },
  {
    name: "ItSecurityAntivirus",
    label: "ccc.ItSecurityAntivirus.label",
  },
  {
    name: "ItSecurityPasswords",
    label: "ccc.ItSecurityPasswords.label",
  },
  {
    name: "ItSecurityEmergencyPlan",
    label: "ccc.ItSecurityEmergencyPlan.label",
  },
  {
    name: "ItSecurityAutomaticUpdate",
    label: "ccc.ItSecurityAutomaticUpdate.label",
  },
  {
    name: "ItSecurityBackupSchedule",
    label: "ccc.ItSecurityBackupSchedule.label",
  },
  {
    name: "ItSecurityBackupPlacement",
    label: "ccc.ItSecurityBackupPlacement.label",
  },
  {
    name: "ItSecurityCourses",
    label: "ccc.ItSecurityCourses.label",
  },
  {
    name: "ItSecurityBackupRestoring",
    label: "ccc.ItSecurityBackupRestoring.label",
  },
  {
    name: "ItSecurityMfAuthentication",
    label: "ccc.ItSecurityMfAuthentication.label",
  },
];
