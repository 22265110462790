import { Grid } from "@mui/material";
import React from "react";

const GridContainer = ({ children }) => {
  return (
    <Grid
      container
      rowSpacing={{ xs: 1, sm: 1.5 }}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
    >
      {children}
    </Grid>
  );
};

export default GridContainer;
