import React from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "inline-flex",
      position: "relative",
      justifyContent: "center",
      alignItems: "center",
      width: 31,
      height: 31,
      borderRadius: "50%",
      background: `linear-gradient(132.44deg, #30B4FF 14.52%, #1E5FAB 68.66%)`,
      marginRight: 10,
    },
    stepSign: {
      color: theme.palette.common.white,
    },
  })
);

const StepNumber = ({ stepSign }) => {
  const classes = useStyles();
  return (
    <span className={classes.root}>
      <span className={classes.stepSign}>{stepSign}</span>
    </span>
  );
};

export default StepNumber;
