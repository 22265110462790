import { Box } from "@mui/material";

import React from "react";
import CalculateCyberRisk from "./CalculateCyberRisk/CalculateCyberRisk";
import Info from "./Info/Info";
import Questions from "./Questions/Questions";

const FormItems = () => {
  return (
    <Box>
      <Info />
      <CalculateCyberRisk />
      <Questions />
    </Box>
  );
};

export default FormItems;
