import { createTheme } from "@mui/material";

export let theme = createTheme({
  typography: {
    fontFamily: [
      'Calibri',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif'
    ].join(','),
  },
  palette: {
    primary: {
      main: "#1E5FAB",
      light: "#EDF8FF",
    },
    secondary: {
      main: "#ED1C24",
    },
    // mode: "dark",
    test: {
      main: "pink",
    },
  },
  landingPageBg: {
    light: `url(${process.env.PUBLIC_URL + "/landingBg.png"})`,
    dark: "", //TODO
  },
});

theme = createTheme(theme, {
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          // background:
          //   theme.palette.mode === "light"
          //     ? `url(${process.env.PUBLIC_URL + "/bg.png"})`
          //     : "",
          // backgroundSize: "cover",
          // backgroundRepeat: "no-repeat",
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        },
      },
    },
  },
});

declare module "@mui/material/styles" {
  interface Theme {
    landingPageBg?: {
      light?: string;
      dark?: string;
    };
  }

  interface Palette {
    test?: {
      main?: string;
    };
  }
  // allow configuration using `createTheme`
  interface PaletteOptions {
    test?: {
      main?: string;
    };
  }
  interface ThemeOptions {
    landingPageBg?: {
      light?: string;
      dark?: string;
    };
  }
}
