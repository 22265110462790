import FormTitle from "components/form/FormTitle/FormTitle";
import SectionWrap from "components/form/SectionWrap/SectionWrap";
import React from "react";
import { useTranslation } from "react-i18next";
import CalculateCyberRiskFormItems from "./CalculateCyberRiskFormItems";

const CalculateCyberRisk = () => {
  const { t } = useTranslation();
  return (
    <SectionWrap>
      <FormTitle>{t("ccc.calculateYourCyberRisk.label")}</FormTitle>
      <CalculateCyberRiskFormItems />
    </SectionWrap>
  );
};

export default CalculateCyberRisk;
