import React from 'react'
import { Box, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'absolute',
    backgroundSize: 'cover',
    inset: 0,
    padding: 20,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    maxWidth: 1500,
    margin: '0 auto',

    [theme.breakpoints.up('md')]: {
      padding: 50,
    },
  },
}))

const PreviewLayout = ({ children }) => {
  const classes = useStyles()
  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <Box>{children}</Box>
      </Box>
    </Box>
  )
}

export default PreviewLayout
