import * as React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useTranslation } from "react-i18next";

type Props = {
  loading: boolean;
  text: string;
  disabled?: boolean;
};

const SubmitBtn = ({
  loading = false,
  text = "Berechnen",
  disabled = false,
}: Props) => {
  const { t } = useTranslation();
  return (
    <LoadingButton
      loading={loading}
      variant="contained"
      disabled={disabled}
      color="primary"
      type="submit"
    >
      {t(text)}
    </LoadingButton>
  );
};

export default SubmitBtn;
