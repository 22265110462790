import React, { useContext } from 'react'
import { Box } from '@mui/material'
import AppContext from 'AppContext'
import Title from 'components/Title/Title'

import SecurityStatus from './SecurityStatus/SecurityStatus'
import SecurityStatistic from './SecurityStatistic/SecurityStatistic'
import LevelOfRisk from './LevelOfRisk/LevelOfRisk'
import ConfirmAndCreateOffer from './ConfirmAndCreateOffer/ConfirmAndCreateOffer'

const CalculationSection = () => {
  const { calculationData } = useContext(AppContext)
  const securityStatusResult =
    calculationData?.calculationOutputData?.numberOfItSecurity

  const riskData = calculationData?.calculationOutputData
  // const riskData = {
  //   predictedDamageCrisisManagement: 53000.0,
  //   predictedDamageBusinessInterruption: 385000.0,
  //   predictedDamageRecovery: 51000.0,
  //   predictedDamageRansom: 120000.0,
  //   predictedDamageAll: 609000.0,
  //   numberOfItSecurity: 8,
  // };
  if (!riskData) return null

  return (
    <Box>
      {/* <Title /> */}
      <SecurityStatus securityStatusResult={securityStatusResult} />

      <SecurityStatistic />
      <LevelOfRisk riskData={riskData} />
      <ConfirmAndCreateOffer />
    </Box>
  )
}

export default CalculationSection
