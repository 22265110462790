export interface IRiskData {
  predictedDamageCrisisManagement: number;
  predictedDamageBusinessInterruption: number;
  predictedDamageRecovery: number;
  predictedDamageRansom: number;
  predictedDamageAll: number;
  numberOfItSecurity: number;
}

export enum ToastType {
  INFO = "info",
  SUCCESS = "success",
  WARNING = "warning",
  ERROR = "error",
}

export enum ECountryCode {
  austria = 834,
  slovakia = 1002,
  romania = 991,
  internal = 834,
  estonia = 885,
}

export enum ECountryValue {
  austria = "de-AT",
  slovakia = "sk-SK",
  romania = "ro-RO",
  internal = "en-GB",
  estonia = "et-EE",
}
