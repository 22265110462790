import { Box, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Divider from "components/Divider/Divider";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
  titleWrap: {
    display: "flex",
    alignItems: "center",
  },
  title: {
    display: "inline-block",
    fontWeight: "600",
    fontSize: 16,

    [theme.breakpoints.up("md")]: {
      fontSize: 20,
    },
  },
}));

const FormTitle = ({ children }) => {
  const classes = useStyles();
  return (
    <Box className={classes.titleWrap}>
      <Box className={classes.title}>{children}</Box>
      <Divider />
    </Box>
  );
};

export default FormTitle;
