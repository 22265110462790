import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { Box } from "@mui/material";
import { ECountryValue } from "library/utils/types";

const DetailsVideo = () => {
  const { t, i18n } = useTranslation();
  const [videoSrc, setVideoSrc] = useState("");
  const videoRef = useRef(null);

  const switchVideoSrcOnLangChange = () => {
    switch (i18n.resolvedLanguage) {
      case ECountryValue.internal:
        setVideoSrc("/video/EN_Video_Cyber_Insurance_GrECo.mp4");
        break;
      case ECountryValue.austria:
        setVideoSrc("/video/DE_Video_Cyber_Insurance_GrECo.mp4");
        break;
      case ECountryValue.romania:
        setVideoSrc("/video/RO_Video_Cyber_Insurance_GrECo.mp4");
        break;
      case ECountryValue.slovakia:
        setVideoSrc("/video/SK_Video_Cyber_Insurance_GrECo.mp4");
        break;
      case ECountryValue.estonia:
        setVideoSrc("/video/EN_Video_Cyber_Insurance_GrECo.mp4");
        break;
      default:
        setVideoSrc("/video/EN_Video_Cyber_Insurance_GrECo.mp4");
    }
  };

  const onLoadVideo = () => {
    videoRef.current.volume = 1; //max volume
    videoRef.current.play(); // autoplay
  };

  useEffect(() => {
    switchVideoSrcOnLangChange();
  }, [i18n.resolvedLanguage]);

  return (
    <Box
      key={videoSrc}
      width="100%"
      sx={{
        background: "black",
        opacity: 0.7,
        color: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <video
        onLoadStart={onLoadVideo}
        ref={videoRef}
        height="100%"
        width={"100%"}
        controls
      >
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support HTML video.
      </video>
    </Box>
  );
};

export default DetailsVideo;
