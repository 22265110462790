import {
  calculatePercent,
  transformNumberWithSeparator,
} from "library/utils/utils";

export const getLegendConfig = ({
  t,
  predictedDamageCrisisManagement,
  predictedDamageBusinessInterruption,
  predictedDamageRecovery,
  predictedDamageRansom,
  predictedDamageAll,
}) => [
  {
    label: t("ccc.crisisManagementCost.label"),
    valuePercent:
      calculatePercent(predictedDamageCrisisManagement, predictedDamageAll) +
      "%",

    backgroundColor: "#ED7D31",
    borderColor: "#ED7D31",
    value: `${transformNumberWithSeparator(
      predictedDamageCrisisManagement
    )} EUR`,
  },
  {
    label: t("ccc.businessInterruption.label"),
    valuePercent:
      calculatePercent(
        predictedDamageBusinessInterruption,
        predictedDamageAll
      ) + "%",

    backgroundColor: "#55C891",
    borderColor: "#55C891",
    value: `${transformNumberWithSeparator(
      predictedDamageBusinessInterruption
    )} EUR`,
  },
  {
    label: t("ccc.recoveryCost.label"),
    valuePercent:
      calculatePercent(predictedDamageRecovery, predictedDamageAll) + "%",

    backgroundColor: "#FFC000",
    borderColor: "#FFC000",
    value: `${transformNumberWithSeparator(predictedDamageRecovery)} EUR`,
  },
  {
    label: t("ccc.ransomDemand.label"),
    valuePercent:
      calculatePercent(predictedDamageRansom, predictedDamageAll) + "%",
    backgroundColor: "#4472C4",
    borderColor: "#4472C4",
    value: `${transformNumberWithSeparator(predictedDamageRansom)} EUR`,
  },
  {
    label: t("ccc.total.label"),
    valuePercent: "100%",
    backgroundColor: "black",
    borderColor: "white",
    value: `${transformNumberWithSeparator(predictedDamageAll)} EUR`,
  },
];
//BAR CONFIG
export const riskChartOptions = {
  animation: {
    duration: 0,
  },
  indexAxis: "y" as const,
  events: [], //disable everything on hover

  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  scales: {
    x: {
      ticks: {
        beginAtZero: true,
        min: 0,
        max: 100,
        callback: function (value, index, ticks) {
          return value + "%";
        },
      },
    },
  },

  responsive: true,
  plugins: {
    legend: {
      position: "right" as const,
      display: false,
    },
    title: {
      display: false,
      position: "bottom" as const,
      text: "",
    },
    tooltip: {
      display: true,
      callbacks: {
        label: function (context) {
          return `${context.raw}%`;
        },
      },
    },
    datalabels: {
      display: true,
      formatter: (value) => {
        // if (value === 100) return ''
        return value + "%";
      },
    },
  },
};

// PIE CONFIG
// export const riskChartOptions = {
//   events: [], //disable everything on hover
//   responsive: true,
//   plugins: {
//     legend: {
//       position: 'right' as const,
//       display: false,
//     },
//     title: {
//       display: false,
//       position: 'bottom' as const,
//       text: '',
//     },
//     tooltip: {
//       display: true,
//       callbacks: {
//         label: function (context) {
//           return `${context.raw}%`
//         },
//       },
//     },
//     datalabels: {
//       display: true,
//       formatter: (value) => {
//         return value + '%'
//       },
//     },
//   },
// }
//STACKED BAR CONFIG
// export const riskChartOptions = {
//   events: [], //disable everything on hover
//   indexAxis: 'y' as const,
//   elements: {
//     bar: {
//       borderWidth: 1,
//     },
//   },
//   responsive: true,
//   scales: {
//     x: {
//       stacked: true,
//       grid: {
//         display: true,
//       },
//       // display: false,
//       ticks: {
//         // display: false,

//         callback: function (value, index, ticks) {
//           return value + '%'
//         },
//       },
//     },
//     y: {
//       display: false,
//       stacked: true,
//       grid: {
//         display: false,
//       },
//     },
//   },

//   plugins: {
//     legend: {
//       position: 'right' as const,
//       display: false,
//     },
//     title: {
//       display: false,
//       position: 'bottom' as const,
//       text: '',
//     },
//     tooltip: {
//       display: true,
//       callbacks: {
//         label: function (context) {
//           return `${context.raw}%`
//         },
//       },
//     },
//     datalabels: {
//       display: true,
//       formatter: (value) => {
//         return value + '%'
//       },
//     },
//   },
// }

export const getRiskChartLabels = ({
  predictedDamageCrisisManagement,
  predictedDamageAll,
  predictedDamageBusinessInterruption,
  predictedDamageRecovery,
  predictedDamageRansom,
}) => [
  // {
  //   label: '',
  //   value: 100,
  //   display: false,
  //   backgroundColor: 'rgba(68, 114, 196, 0.0)',
  //   borderColor: 'rgba(68, 114, 196, 0.0)',
  // },
  {
    label: "",
    value: calculatePercent(
      predictedDamageCrisisManagement,
      predictedDamageAll
    ),
    backgroundColor: "rgba(237, 125, 49, 0.6)",
    borderColor: "#ED7D31",
  },
  {
    label: "",
    value: calculatePercent(
      predictedDamageBusinessInterruption,
      predictedDamageAll
    ),
    backgroundColor: "rgba(85, 200, 145, 0.6)",
    borderColor: "#55C891",
  },
  {
    label: "",
    value: calculatePercent(predictedDamageRecovery, predictedDamageAll),
    backgroundColor: "rgba(255, 192, 0, 0.6)",
    borderColor: "#FFC000",
  },
  {
    label: "",
    value: calculatePercent(predictedDamageRansom, predictedDamageAll),
    backgroundColor: "rgba(68, 114, 196, 0.6)",
    borderColor: "#4472C4",
  },
];

export const levelOfRiskLegendRows = (t) => [
  {
    title: t("ccc.crisisManagementCostExplaned.label"),
    titleFirst: t("ccc.crisisManagementCost.label"),
  },
  {
    title: t("ccc.businessInterruptionExplaned.label"),
    titleFirst: t("ccc.businessInterruption.label"),
  },
  {
    title: t("ccc.recoveryCostExplaned.label"),
    titleFirst: t("ccc.serversAndClientsRecoveryCost"),
  },
  {
    title: t("ccc.ransomDemandExplaned.label"),
    titleFirst: t("ccc.ransomDemand.label"),
  },
];
