import React, { useContext, useState } from "react";
import { Formik } from "formik";
import FormItems from "./FormItems/FormItems";
import Details from "./Details";
import SubmitBtn from "components/controls/SubmitBtn";
import { Box } from "@mui/material";
import * as yup from "yup";
import { ActiveTab } from "../MainForm";
import { apiService } from "services/api";
import AppContext from "AppContext";
import ToastContext from "components/Toast/ToastContext";
import { ToastType } from "library/utils/types";
import LoadingModal from "components/LoadingModal/LoadingModal";
import ReCAPTCHA from "react-google-recaptcha";

const initialValues = {
  EmployeeName: "",
  CompanyName: "",
  EmployeePosition: "",
  EmployeeEmailAddress: "",

  SicCodeChoiceCode: "",
  RevenueRangeEurCode: "",
  NumberOfEmployeesRangeCode: "",
  NumberOfServersRangeCode: "",
  NumberOfClientsRangeCode: "",
  NumberOfDaysChoiceCode: "",

  ItSecurityAdminRights: "",
  ItSecurityAntivirus: "",
  ItSecurityPasswords: "",
  ItSecurityEmergencyPlan: "",
  ItSecurityAutomaticUpdate: "",
  ItSecurityBackupSchedule: "",
  ItSecurityBackupPlacement: "",
  ItSecurityCourses: "",
  ItSecurityBackupRestoring: "",
  ItSecurityMfAuthentication: "",
};

const FormSection = ({ setExpanded }) => {
  const [loading, setLoading] = useState(false);
  const { setFormSubmited, setCalculationData, state } = useContext(AppContext);
  const { setToastState } = useContext(ToastContext);

  const [verified, setVerified] = useState(false);

  const validationSchema = yup.object().shape({
    EmployeeName: yup.string().required(),
    CompanyName: yup.string().required(),
    EmployeePosition: yup.string(),
    EmployeeEmailAddress: yup.string().email().required(),
    SicCodeChoiceCode: yup.mixed().required(),
    RevenueRangeEurCode: yup.mixed().required(),
    NumberOfEmployeesRangeCode: yup.mixed().required(),
    NumberOfServersRangeCode: yup.mixed().required(),
    NumberOfClientsRangeCode: yup.mixed().required(),
    NumberOfDaysChoiceCode: yup.mixed().required(),

    ItSecurityAdminRights: yup.mixed().required(),
    ItSecurityAntivirus: yup.mixed().required(),
    ItSecurityPasswords: yup.mixed().required(),
    ItSecurityEmergencyPlan: yup.mixed().required(),
    ItSecurityAutomaticUpdate: yup.mixed().required(),
    ItSecurityBackupSchedule: yup.mixed().required(),
    ItSecurityBackupPlacement: yup.mixed().required(),
    ItSecurityCourses: yup.mixed().required(),
    ItSecurityBackupRestoring: yup.mixed().required(),
    ItSecurityMfAuthentication: yup.mixed().required(),
  });

  const handleVerify = () => {
    setVerified(true);
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    const payload = { ...values };
    Object.keys(payload).forEach((key) => {
      if (payload[key] === "true") {
        payload[key] = true;
      }
      if (payload[key] === "false") {
        payload[key] = false;
      }
    });

    try {
      const response = await apiService.executeCalculation({
        ...payload,
        CountryCode: state.CountryCode,
        SellerName: state.SellerName,
      });
      if (response) {
        setExpanded(ActiveTab.calculation);
        setFormSubmited(true);
        setCalculationData(response);
        setLoading(false);
      }
    } catch (error) {
      const message =
        error?.response?.data?.messageCode ||
        error?.response?.data[0]?.messageCode;
      setToastState({
        open: true,
        message,
        severity: ToastType.ERROR,
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <LoadingModal open={loading} />
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={async (values, { setSubmitting }) => {
          handleSubmit(values);
          setSubmitting(false);
        }}
      >
        {(props) => {
          return (
            <form onSubmit={props.handleSubmit} noValidate autoComplete="on">
              <Details />
              <FormItems />
              <Box marginTop={4} display="flex" justifyContent="end">
                {verified ? (
                  <SubmitBtn text="ccc.calculate.label" loading={loading} />
                ) : (
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                    onChange={handleVerify}
                  />
                )}
              </Box>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default FormSection;
