import { Grid, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import SwitchField from "components/controls/SwitchField";
import GridContainer from "components/form/GridContainer/GridContainer";
import GridWrap from "components/form/GridWrap/GridWrap";
import { FormikProps, FormikValues, useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { questionsConfig } from "./questionsConfig";

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    marginLeft: 10,
    fontSize: 12,

    [theme.breakpoints.up("lg")]: {
      marginLeft: 50,
      fontSize: 16,
    },
  },
}));

const QuestionsFormItems = () => {
  const { setFieldValue } = useFormikContext() as FormikProps<FormikValues>;
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <GridWrap>
      <GridContainer>
        {questionsConfig.map((question) => (
          <Grid item xs={12} key={question.name}>
            <Box display={"flex"} justifyContent="start" alignItems={"center"}>
              <SwitchField name={question.name} setFieldValue={setFieldValue} />
              <Typography className={classes.label}>
                {t(question.label)}
              </Typography>
            </Box>
          </Grid>
        ))}
        {/* <GridItem>
          <SelectInputField
            value={values?.ItSecurityAdminRights}
            handleChange={handleChange}
            errors={errors}
            required
            options={options}
            name={"ItSecurityAdminRights"}
            label={t("ccc.ItSecurityAdminRights.label")}
            disabled={false}
          />
        </GridItem> */}
      </GridContainer>
    </GridWrap>
  );
};

export default QuestionsFormItems;
