import * as React from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { FormikProps, FormikValues } from 'formik'

type Props = {
  setFieldValue: Function
  name: string
  disabled: boolean
  label: string
  errors?: any
  required?: boolean
  options: any
  [x: string]: any //rest props
}

const labelMaxCharForMobile = 45

const ComboBoxField = ({
  disabled,
  label,
  name,
  setFieldValue,
  options,
  error = '',
  errors,
  required,
  ...props
}: Props) => {
  const [value, setValue] = React.useState<any | string>('')

  const maxLabelCharReached = label.length > labelMaxCharForMobile
  return (
    <Autocomplete
      disablePortal
      fullWidth
      id="combo-box-demo"
      value={value}
      onChange={(event: any, newValue: any | null) => {
        setValue(newValue)
        setFieldValue(name, newValue?.id || null)
      }}
      options={options}
      isOptionEqualToValue={(option, value) => {
        if (value === '') {
          return true
        }
        return option.id === value.id
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label={label}
            // InputLabelProps={{
            //   style: {
            //     fontSize: "14px",
            //   },
            // }}
            // sx={{
            //   "& label, input, li": {
            //     fontSize: { xs: "12px", lg: "16px" },
            //   },
            // }}
            // sx={{
            //   '& label': {
            //     position: {
            //       // xs: maxLabelCharReached ? 'relative' : 'absolute',
            //       lg: 'absolute',
            //     },
            //     whiteSpace: {
            //       xs: maxLabelCharReached ? 'normal' : 'break-spaces',
            //       lg: 'break-spaces',
            //     },
            //   },
            //   '& input': {
            //     marginTop: { xs: maxLabelCharReached ? '40px' : '0', lg: 0 },
            //     lg: 0,
            //   },
            // }}

            // SelectProps={}
            variant="standard"
            size="small"
            autoComplete="on"
            name={name}
            required={required}
            error={!!errors[name]}
            helperText={errors[name]}
          />
        )
      }}
    />
  )
}

export default ComboBoxField
