import { Grid, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import AppContext from "AppContext";
import { strings } from "library/shared/shared";
import { ECountryCode, ECountryValue } from "library/utils/types";
import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import LegalSection from "./LegalSection";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  logoWrap: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("lg")]: {
      justifyContent: "start",
    },
  },
  vmgLink: {
    width: "100%",
    fontWeight: "bold",
  },
  erstePartnerLogo: {
    height: "75px",
    marginBottom: "5px",
  },
  slspLogo: {
    height: "75px",
    marginBottom: "5px",
  },
  bcrLogo: {
    height: "75px",
    marginBottom: "5px",
  },

  grecoText: {
    color: theme.palette.common.black,
    fontWeight: 600,
    textTransform: "uppercase",
    fontSize: "14px",
  },
  grecoImgWrap: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("lg")]: {
      justifyContent: "end",
    },
  },
  grecoImg: {
    width: "70px",
    height: "90px",
  },
}));

const LandingLayoutFooter = () => {
  const { state } = useContext(AppContext);

  const classes = useStyles();

  const countryCode = state.CountryCode;
  const sellerName = state.SellerName;

  const logoSettings = useMemo(() => {
    switch (countryCode) {
      case ECountryCode.austria:
        return {
          src: "/partnerOfErste.png",
          logoClass: classes.erstePartnerLogo,
        };
      case ECountryCode.slovakia:
        return {
          src: "/slspLogo.png",
          logoClass: classes.slspLogo,
        };
      case ECountryCode.romania:
        return {
          src: "/bcrLogo.png",
          logoClass: classes.bcrLogo,
        };
      case ECountryCode.estonia:
        return {
          src: "/grecoLogo.png",
          logoClass: classes.grecoImg,
        };
      default:
        return {
          src: "",
          logoClass: "",
        };
    }
  }, [
    classes.bcrLogo,
    classes.erstePartnerLogo,
    classes.slspLogo,
    classes.grecoImg,
    countryCode,
  ]);

  const isInternal = sellerName === strings.GrECo_internal;

  return (
    <Box className={classes.container}>
      <Grid container rowSpacing={2}>
        <Grid item xs={12} lg={3}>
          <Box className={classes.logoWrap}>
            {!isInternal && (
              <img
                src={`${process.env.PUBLIC_URL}${logoSettings.src}`}
                alt="logo"
                className={logoSettings.logoClass}
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={12} lg={6}>
          <LegalSection />
        </Grid>
        <Grid item xs={12} lg={3}>
          <Box className={classes.grecoImgWrap}>
            <img
              src={process.env.PUBLIC_URL + "/grecoMember.png"}
              alt="greco"
              className={classes.grecoImg}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LandingLayoutFooter;
