import AppContext from 'AppContext'
import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { getTranslatedTaxonomyOption } from 'library/utils/utils'

const useTaxonomyOptions = () => {
  const { taxonomy } = useContext(AppContext) as any
  const { t } = useTranslation()

  const sicOptions = useMemo(() => {
    if (!taxonomy.SicCodeChoice) return []
    return getTranslatedTaxonomyOption(
      taxonomy?.SicCodeChoice.filter(
        (item) => item.code !== 'SicCodeChoice.CONSTRUCTION_AND_OTHER_TRADES',
      ),
      t,
    )
  }, [t, taxonomy?.SicCodeChoice])

  const revenueOptions = useMemo(() => {
    if (!taxonomy.RevenueRangeEur) return []

    return getTranslatedTaxonomyOption(taxonomy?.RevenueRangeEur, t)
  }, [t, taxonomy?.RevenueRangeEur])

  const numberOfEmployeesOptions = useMemo(() => {
    if (!taxonomy.NumberOfEmployeesRange) return []

    return getTranslatedTaxonomyOption(taxonomy?.NumberOfEmployeesRange, t)
  }, [t, taxonomy?.NumberOfEmployeesRange])

  const numberOfClientsOptions = useMemo(() => {
    if (!taxonomy.NumberOfClientsRange) return []

    return getTranslatedTaxonomyOption(taxonomy?.NumberOfClientsRange, t)
  }, [t, taxonomy?.NumberOfClientsRange])

  const numberOfDaysOptions = useMemo(() => {
    if (!taxonomy.NumberOfDaysChoice) return []

    return getTranslatedTaxonomyOption(taxonomy?.NumberOfDaysChoice, t)
  }, [t, taxonomy?.NumberOfDaysChoice])

  const numberOfServersOptions = useMemo(() => {
    if (!taxonomy.NumberOfServersRange) return []

    return getTranslatedTaxonomyOption(taxonomy?.NumberOfServersRange, t)
  }, [t, taxonomy?.NumberOfServersRange])

  return {
    sicOptions,
    revenueOptions,
    numberOfEmployeesOptions,
    numberOfClientsOptions,
    numberOfDaysOptions,
    numberOfServersOptions,
  }
}

export default useTaxonomyOptions
