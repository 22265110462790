import * as React from 'react';
import './GrecoHeader.scss';
// -- Fabric UI
import {
  IconButton,
  Persona,
  PersonaSize,
  SearchBox,
  TooltipHost,
  ITooltipHostProps,
} from 'office-ui-fabric-react';
import { mergeStyleSets } from 'office-ui-fabric-react';
import { GrecoPanel } from './GrecoPanel/GrecoPanel';
import { AppLauncher } from './AppLauncher/AppLauncher';
import { IGrecoHeaderStrings } from './IGrecoHeaderStrings';
import { PanelType } from './GrecoPanel/PanelType';
import { IGrecoPanelStrings } from './GrecoPanel/IGrecoPanelStrings';
import { IUserSettingsStrings } from './GrecoPanel/UserSettings/IUserSettingsStrings';
import { INotificationStrings } from './GrecoPanel/Notification/INotificationStrings';
import { ISettingsStrings } from './GrecoPanel/Settings/ISettingsStrings';

interface IProps {
  headerConfig?: any;
  user?: any;
  panelStatus?: Function;
  tooltipStatusChange?: any;
  tooltipsStatus?: any;
  logo?: any;
  additionalLogo?: any;
  logout?: any;
  searchOnChange?: any;
  searchOnSearch?: any;
  search?: string;
  searchTooltipProps?: ITooltipHostProps;
  darkMode?: any;
  headerStrings?: IGrecoHeaderStrings;
  showErrorAndWarningMessages?: () => void;
  messagesCount?: number;
  gosNavigation?: any;
}

interface IState {
  panelType: PanelType | null;
  panelIsOpen: any;
  appLuncherIsOpen: any;
  headerConfigDiff: any;
}

export const iconStyle = mergeStyleSets({
  icon: {
    height: '50px',
    width: '50px',
    color: 'white',
    fontSize: '26px',
  },
});

export class GrecoHeader extends React.PureComponent<IProps, IState> {
  public defaultProps = {
    headerConfig: {
      application_name: 'GrECo Header',
      app_launcher_visible: true,
      notifications_visible: true,
      help_visible: true,
      account: true,
      search_visible: true,
      search_autofocus: false,
      logo_visible: true,
      title_visible: true,
      tooltipsStatus: true,
      settings_visible: true,
      settings_tooltip_switch: true,
      settings_language_settings: true,
      settings_time_zone_settings: true,

      defaultLanguage: 'en-GB',
      defaultTimeZone: 'UTC',

      disableActionSpinner: false,
      test: false,
      debug: false,

      userMenuShowMyProfileLink: true,
      userMenuShowMyAccountLink: true,
    },
  };

  componentDidMount() {
    this.updateConfig(this.props.headerConfig);
  }

  componentWillReceiveProps(nextProps: any) {
    this.updateConfig(nextProps.headerConfig);
  }

  private updateConfig(headerConfig: IProps) {
    let configDiff = Object.assign(
      this.defaultProps.headerConfig,
      headerConfig
    );
    this.setState({ headerConfigDiff: configDiff });
  }

  constructor(props: IProps) {
    super(props);

    this.state = {
      panelType: null,
      panelIsOpen: false,
      appLuncherIsOpen: false,
      headerConfigDiff: {},
    };
  }
  public render(): JSX.Element {
    var panelStrings: IGrecoPanelStrings | undefined;
    if (this.props.headerStrings) {
      if (this.state.panelType === PanelType.Notifications) {
        panelStrings = this.props.headerStrings
          .notificationsPanelStrings as IGrecoPanelStrings;
      } else if (this.state.panelType === PanelType.Help) {
        panelStrings = this.props.headerStrings.helpPanelStrings;
      } else if (this.state.panelType === PanelType.Settings) {
        panelStrings = this.props.headerStrings
          .settingsPanelStrings as IGrecoPanelStrings;
      } else if (this.state.panelType === PanelType.UserSettings) {
        panelStrings = this.props.headerStrings
          .userSettingsPanelStrings as IGrecoPanelStrings;
      }
    }

    return (
      <header className="greco-header">
        {this.state.headerConfigDiff.app_launcher_visible && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            className="greco-header__tick"
            width="24"
            height="24"
          >
            <path d="M 0,0 24,0 0,12 z" />
          </svg>
        )}

        <GrecoPanel
          onDismiss={this.closePanel.bind(this)}
          panelIsOpen={this.state.panelIsOpen}
          panelType={this.state.panelType}
          userData={this.props.user}
          logout={this.props.logout}
          headerConfig={this.state.headerConfigDiff}
          tooltipStatusChange={this.props.tooltipStatusChange}
          darkMode={this.props.darkMode}
          panelStrings={panelStrings}
          tooltipStatus={this.props.tooltipsStatus}
          showErrorAndWarningMessages={this.props.showErrorAndWarningMessages}
          messagesCount={this.props.messagesCount}
        />

        <AppLauncher
          closeAppLuncher={this.closeAppLuncher.bind(this)}
          appLuncherIsOpen={this.state.appLuncherIsOpen}
          gosNavigation={this.props.gosNavigation}
        />

        {this.state.headerConfigDiff.app_launcher_visible ? (
          <div className="greco-header__app-launcher">
            <IconButton
              onClick={() => this.openAppLuncher()}
              className={iconStyle.icon}
              iconProps={{ iconName: 'WaffleOffice365' }}
              title="App Launcher"
              ariaLabel="App Launcher"
            />
          </div>
        ) : null}

        <div className="greco-header__middle">
          <div className="middle-content">
            <div className="middle-content__title">
              {this.state.headerConfigDiff.logo_visible ? (
                <img
                  className="logo"
                  src={this.props.logo}
                  alt={this.state.headerConfigDiff.application_name}
                />
              ) : null}

              {this.state.headerConfigDiff.logo_visible ? (
                <span>{this.state.headerConfigDiff.application_name}</span>
              ) : null}
            </div>

            <div className="middle-content__search">
              {this.state.headerConfigDiff.search_visible ? (
                <TooltipHost
                  hidden={true}
                  content={
                    this.props.headerStrings &&
                    this.props.headerStrings.searchTooltipText
                  }
                  {...this.props.searchTooltipProps}
                >
                  <SearchBox
                    styles={{
                      root: {
                        width: '100%',
                        maxWidth: '468px',
                        minWidth: '304px',
                      },
                    }}
                    placeholder={
                      (this.props.headerStrings &&
                        this.props.headerStrings.searchPlaceholder) ||
                      'Search'
                    }
                    onChange={(newValue: any) =>
                      this.props.searchOnChange(newValue)
                    }
                    onSearch={(newValue: any) =>
                      this.props.searchOnSearch(newValue)
                    }
                    className={'main-search'}
                    value={this.props.search}
                    autoFocus={!!this.state.headerConfigDiff.search_autofocus}
                  />
                </TooltipHost>
              ) : null}
            </div>

            {this.props.additionalLogo && (
              <img
                className="additional-logo"
                src={this.props.additionalLogo}
                alt={this.state.headerConfigDiff.application_name}
              />
            )}

            <div className="middle-content__icons">
              {this.state.headerConfigDiff.notifications_visible ? (
                <IconButton
                  checked={this.state.panelType === PanelType.Notifications}
                  onClick={() => this.togglePanel(PanelType.Notifications)}
                  className={iconStyle.icon}
                  iconProps={{ iconName: 'Ringer' }}
                  title="Ringer"
                  ariaLabel="Ringer"
                  onRenderChildren={() =>
                    this.props.messagesCount ? (
                      <div className="message-count">
                        <span>{this.props.messagesCount}</span>
                      </div>
                    ) : null
                  }
                />
              ) : null}

              {this.state.headerConfigDiff.settings_visible ? (
                <IconButton
                  checked={this.state.panelType === PanelType.Settings}
                  onClick={() => this.togglePanel(PanelType.Settings)}
                  className={iconStyle.icon}
                  iconProps={{ iconName: 'Settings' }}
                  title="Settings"
                  ariaLabel="Settings"
                />
              ) : null}

              {this.state.headerConfigDiff.help_visible ? (
                <IconButton
                  checked={this.state.panelType === PanelType.Help}
                  onClick={() => this.togglePanel(PanelType.Help)}
                  className={iconStyle.icon}
                  iconProps={{ iconName: 'Help' }}
                  title="Help"
                  ariaLabel="Help"
                />
              ) : null}
            </div>
          </div>
        </div>

        {this.state.headerConfigDiff.account ? (
          <div
            className={
              this.state.panelType === PanelType.UserSettings
                ? 'greco-header__user is-checked'
                : 'greco-header__user'
            }
            onClick={() => this.togglePanel(PanelType.UserSettings)}
          >
            {this.props.user ? (
              <Persona
                {...{
                  text: this.props.user.displayName,
                  imageUrl: this.props.user.photoSmall,
                }}
                hidePersonaDetails={true}
                size={PersonaSize.size40}
              />
            ) : null}
          </div>
        ) : null}
      </header>
    );
  }

  private openAppLuncher = () => {
    this.setState({ appLuncherIsOpen: true });
  };
  private closeAppLuncher = () => {
    this.setState({ appLuncherIsOpen: false });
  };

  private togglePanel = (type: PanelType) => {
    type === this.state.panelType ? this.closePanel() : this.openPanel(type);
  };

  private openPanel(type: PanelType) {
    if (this.props.panelStatus) this.props.panelStatus(true);
    this.setState({ panelIsOpen: true, panelType: type });
  }

  private closePanel() {
    if (this.props.panelStatus) this.props.panelStatus(false);
    this.setState({ panelIsOpen: false, panelType: null });
  }
}
