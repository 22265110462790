import React from 'react'

import { Box, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

import LanguageSelect from 'components/LanguageSelect/LanguageSelect'

import LandingLayoutFooter from './LandingLayoutFooter'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background:
      theme.palette.mode === 'light'
        ? theme.landingPageBg.light
        : theme.landingPageBg.dark,
    position: 'absolute',
    backgroundSize: 'cover',
    inset: 0,
    overflow: 'auto',
    overflowY: 'scroll',
    [theme.breakpoints.up('md')]: {
      position: 'fixed',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '20px 20px 0',
    maxWidth: 1400,
    margin: '0 auto',

    [theme.breakpoints.up('md')]: {
      padding: 50,
    },
  },
}))

const LandingLayout = ({ children }) => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <LanguageSelect />
      <Box className={classes.container}>
        <Box>{children}</Box>
        <LandingLayoutFooter />
      </Box>
    </Box>
  )
}

export default LandingLayout
