import * as React from 'react'
import { useTranslation } from 'react-i18next'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Typography } from '@mui/material'
import { useTheme } from '@emotion/react'

import StyledTableCell from 'components/StyledTableCell/StyledTableCell'
import { statusTableRows } from './statusTableRows'

type Props = {
  result: number
}

const SecurityStatusTable = ({ result }: Props) => {
  const theme = useTheme() as any
  const { t } = useTranslation()

  const rows = React.useMemo(() => {
    return statusTableRows(t)
  }, [t])

  return (
    <TableContainer>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow sx={{ width: '100%' }}>
            <StyledTableCell>{t('ccc.measures.label')}</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.label}>
              <TableCell
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  background: row.values.includes(result)
                    ? theme.palette.primary.main
                    : 'inherit',
                  color: row.values.includes(result)
                    ? theme.palette.common.white
                    : 'inherit',
                }}
              >
                <Typography
                  sx={{
                    minWidth: '70px',
                    fontSize: { xs: '12px', lg: '16px' },
                  }}
                >
                  {row.label}
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: '12px', lg: '16px' },
                  }}
                >
                  {row.title}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default SecurityStatusTable
