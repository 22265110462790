import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import FormTitle from 'components/form/FormTitle/FormTitle'
import React from 'react'
import { useTranslation } from 'react-i18next'
import SecurityStatusTable from './SecurityStatusTable'

type Props = {
  securityStatusResult: number
}
const SecurityStatus = ({ securityStatusResult }: Props) => {
  const { t } = useTranslation()
  return (
    <Box marginTop={2}>
      <FormTitle>{t('ccc.securityStatus.label')}</FormTitle>
      {/* <Box marginTop={3}>
        <Typography>
          {t('ccc.basedOnInformation.label')}{' '}
          <b>
            {securityStatusResult} {t('ccc.from.label')} 10{' '}
          </b>
          {t('ccc.measuresImplemented.label')}.
        </Typography>
      </Box> */}
      <Box marginTop={2}>
        <SecurityStatusTable result={securityStatusResult} />
      </Box>
    </Box>
  )
}

export default SecurityStatus
