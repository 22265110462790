import React, { useEffect, useState } from "react";
import { FormControlLabel, FormGroup, Stack, Typography } from "@mui/material";
import StyledSwitch from "components/StyledSwitch/StyledSwitch";
import { useTranslation } from "react-i18next";

type Props = {
  setFieldValue: Function;
  name: string;
};

const SwitchField = ({ name, setFieldValue }: Props) => {
  const [checked, setChecked] = useState(false);
  const { t } = useTranslation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    setChecked(value);
  };

  useEffect(() => {
    setFieldValue(name, checked);
  }, [checked, name, setFieldValue]);

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Typography
        sx={{
          margin: "0 !important",
          padding: "0 !important",
          fontSize: { xs: "12px", lg: "16px" },
        }}
        // color={checked ? "disabled" : "secondary"}
      >
        {t("ccc.no.label")}
      </Typography>
      <FormGroup
        sx={{
          margin: "0 !important",
          padding: "0 !important",
          transform: { xs: "scale(0.8)", lg: "scale(1)" },
        }}
      >
        <FormControlLabel
          name={name}
          sx={{ margin: "0 !important", padding: "0 !important" }}
          control={
            <StyledSwitch
              sx={{ m: 1 }}
              checked={checked}
              onChange={handleChange}
            />
          }
          label=""
        />
      </FormGroup>
      <Typography
        sx={{
          margin: "0 !important",
          padding: "0 !important",
          fontSize: { xs: "12px", lg: "16px" },
        }}
        // color={checked ? "primary" : "disabled"}
      >
        {t("ccc.yes.label")}
      </Typography>
    </Stack>
  );
};

export default SwitchField;
