import React from "react";
import { useState } from "react";
import Toast from "./Toast";
import ToastContext from "./ToastContext";

const ToastProvider = (props) => {
  const [state, setState] = useState({
    open: false,
    message: "",
    severity: "",
    delay: 3000,
  });

  const setToastState = ({ open, severity, message, delay = 3000 }) => {
    setState({
      open,
      severity,
      message,
      delay,
    });
  };

  const handleClose = () => {
    setState((state) => ({
      ...state,
      open: false,
    }));
  };

  const providerValue = {
    state,
    setToastState,
  } as any;

  return (
    <ToastContext.Provider value={providerValue}>
      <Toast
        state={{
          ...state,
          handleClose,
        }}
      />
      {props.children}
    </ToastContext.Provider>
  );
};

export default ToastProvider;
