import { ECountryCode } from "library/utils/types";

export const countryOptions = [
  {
    countryName: "austria",
    countryCode: ECountryCode.austria,
  },
  {
    countryName: "slovakia",
    countryCode: ECountryCode.slovakia,
  },
  {
    countryName: "romania",
    countryCode: ECountryCode.romania,
  },
  {
    countryName: "estonia",
    countryCode: ECountryCode.estonia,
  },
  {
    countryName: "internal",
    countryCode: ECountryCode.romania,
  },
];

export const strings = {
  GrECo_internal: "GrECo_internal",
};
