import { Typography } from '@mui/material'
import { makeStyles, useTheme } from '@mui/styles'
import { Theme } from '@mui/system'

import LevelOfRisk from 'components/Main/CalculationSection/LevelOfRisk/LevelOfRisk'
import SecurityStatistic from 'components/Main/CalculationSection/SecurityStatistic/SecurityStatistic'
import SecurityStatus from 'components/Main/CalculationSection/SecurityStatus/SecurityStatus'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { formatDate } from 'library/utils/utils'

import PreviewResponses from './PreviewResponses'

const questionResponsesFieldsToShow = [
  'itSecurityAdminRights',
  'itSecurityAntivirus',
  'itSecurityAutomaticUpdate',
  'itSecurityBackupPlacement',
  'itSecurityBackupRestoring',
  'itSecurityBackupSchedule',
  'itSecurityCourses',
  'itSecurityEmergencyPlan',
  'itSecurityMfAuthentication',
  'itSecurityPasswords',
]

const infoFieldsToShow = [
  'employeeName',
  'companyName',
  'employeePosition',
  'employeeEmailAddress',
]

const generalInfo = [
  'sicCodeChoiceCode',
  'revenueRangeEurCode',
  'numberOfEmployeesRangeCode',
  'numberOfServersRangeCode',
  'numberOfClientsRangeCode',
  'numberOfDaysChoiceCode',
]

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    textAlign: 'center',
    fontSize: '22px',
    marginTop: 30,
    [theme.breakpoints.up('lg')]: {
      fontSize: '32px',
      marginTop: 50,
    },
  },
}))

const Preview = ({ calculationResults }) => {
  const calculationInputData = calculationResults?.calculationInputData
  const securityStatusResult =
    calculationResults?.calculationOutputData?.numberOfItSecurity
  const riskData = calculationResults?.calculationOutputData
  const offerRequestedAt = calculationResults?.offerRequestedAt
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <>
      <Typography className={classes.title}>
        {t('ccc.entries.label')}
      </Typography>
      <PreviewResponses
        title={'ccc.info.label'}
        offerRequestedAt={formatDate(new Date(offerRequestedAt))}
        calculationInputData={calculationInputData}
        fieldsToShow={infoFieldsToShow}
      />
      <PreviewResponses
        title={'ccc.calculateYourCyberRisk.label'}
        calculationInputData={calculationInputData}
        fieldsToShow={generalInfo}
        valuesFromTaxonomy
      />
      <PreviewResponses
        // title={'ccc.calculateYourCyberRisk.label'}
        calculationInputData={calculationInputData}
        fieldsToShow={questionResponsesFieldsToShow}
      />

      <Typography className={classes.title}>
        {t('ccc.results.label')}
      </Typography>
      <SecurityStatus securityStatusResult={securityStatusResult} />
      <SecurityStatistic />
      <LevelOfRisk riskData={riskData} />
      {/* <button onClick={() => MSGraphAuth.logout()}>logout</button> */}
    </>
  )
}

export default Preview
