import React from 'react'
import { Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Box } from '@mui/system'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    background: theme.palette.primary.main,
    height: '2px',
    width: '50px',
    marginTop: '5px',
    [theme.breakpoints.up('md')]: {
      height: '3px',
    },
  },
}))

const UnderlineBox = () => {
  const classes = useStyles()
  return <Box className={classes.container}></Box>
}

const SectionTitle = ({ title, subtitle }) => {
  return (
    <Box marginTop={4}>
      <Typography
        color={'primary'}
        sx={{ fontSize: { xs: 18, md: 30 } }}
        fontWeight="bold"
      >
        {title}
      </Typography>
      <Typography
        color={'primary'}
        sx={{ fontSize: { xs: 14, md: 22 } }}
        fontWeight="bold"
      >
        {subtitle}
      </Typography>
      <UnderlineBox />
    </Box>
  )
}

export default SectionTitle
