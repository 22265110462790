import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useTranslation } from "react-i18next";

import { ECountryValue } from "library/utils/types";
import { setYupLocale } from "library/utils/utils";

type FlagType = {
  countryCode: string;
  alt: string;
};

const FlagImg = ({ countryCode, alt }: FlagType) => (
  <img
    src={`https://flagcdn.com/${countryCode}.svg`}
    width="30"
    alt={alt}
  ></img>
);

const LanguageSelect = () => {
  const { t, i18n } = useTranslation();
  const selectedLanguage = i18n.language;
  const [lang, setLang] = useState(selectedLanguage);

  const handleChange = (event: SelectChangeEvent) => {
    const value: string = event.target.value;
    i18n.changeLanguage(value);
    setLang(value);
  };

  useEffect(() => {
    setYupLocale(null, t);
  }, [t, i18n]);

  return (
    <Box
      position={"absolute"}
      sx={{
        top: { xs: 5, lg: 20 },
        right: { xs: 10, lg: 40 },
      }}
      right={40}
      zIndex={999}
    >
      <FormControl fullWidth variant="standard">
        <InputLabel id="demo-simple-select-label">
          {/* {t('ccc.language.label')} */}
        </InputLabel>
        <Select
          size="small"
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={lang}
          label="Age"
          onChange={handleChange}
        >
          <MenuItem value={ECountryValue.austria}>
            <FlagImg countryCode="at" alt="austria" />
          </MenuItem>
          <MenuItem value={ECountryValue.estonia}>
            <FlagImg countryCode="ee" alt="estonia" />
          </MenuItem>
          <MenuItem value={ECountryValue.internal}>
            <FlagImg countryCode="gb" alt="great britain" />
          </MenuItem>
          <MenuItem value={ECountryValue.romania}>
            <FlagImg countryCode="ro" alt="romania" />
          </MenuItem>
          <MenuItem value={ECountryValue.slovakia}>
            <FlagImg countryCode="sk" alt="slovakia" />
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default LanguageSelect;
