import { TFunction } from 'i18next'
import * as yup from 'yup'

export const getTranslatedTaxonomyOption = (taxonomy, t) => {
  return taxonomy.map((item) => {
    return {
      label: t(item.code),
      id: item.id,
    }
  })
}

export const transformNumberWithSeparator = (number: number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}

export const calculatePercent = (value, total) => {
  return ((Number(value) / Number(total)) * 100).toFixed(0)
}

export const formatDate = (date) => {
  return (
    ('0' + date.getDate()).slice(-2) +
    '.' +
    ('0' + (date.getMonth() + 1)).slice(-2) +
    '.' +
    date.getFullYear() +
    ' ' +
    ('0' + date.getHours()).slice(-2) +
    ':' +
    ('0' + date.getMinutes()).slice(-2) +
    'h'
  )
}

export const setYupLocale = (_: unknown, t: TFunction) => {
  yup.setLocale({
    mixed: {
      required: t('ccc.required.label'),
    },
    string: {
      email: t('ccc.invalidEmail.label'),
    },
  })
}
