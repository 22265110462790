import * as React from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { useTranslation } from "react-i18next";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

type Props = {
  state: {
    severity: any;
    message: string;
    handleClose: any;
    open: boolean;
    delay: number;
  };
};

const Toast = ({ state }: Props) => {
  const { severity = "error", message, handleClose, open, delay } = state;
  const { t } = useTranslation();
  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar
        open={open}
        autoHideDuration={delay}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {t(message)}
        </Alert>
      </Snackbar>
    </Stack>
  );
};

export default Toast;
