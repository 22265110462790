import React from "react";
import { Typography } from "@mui/material";
import { Box, Theme } from "@mui/system";
import { makeStyles, useTheme } from "@mui/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  text: {
    fontSize: "12px",
    minWidth: "16px",
    [theme.breakpoints.up("lg")]: {
      fontSize: "16px",
    },
  },
}));

const PreviewResponseItem = ({ label, value }) => {
  const classes = useStyles();
  const theme = useTheme() as Theme;
  const { t } = useTranslation();
  return (
    <Box display="flex" marginTop={1} alignItems="center">
      <Typography
        className={classes.text}
        color={theme.palette.grey[800]}
        fontWeight={600}
        marginRight="5px"
      >
        {t(label)}:
      </Typography>
      <Typography className={classes.text} color={theme.palette.grey[700]}>
        {value}
      </Typography>
    </Box>
  );
};

export default PreviewResponseItem;
