import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import DetailsVideo from "./Details/DetailsVideo";
import { makeStyles, useTheme } from "@mui/styles";
import { Theme } from "@mui/system";

const list = [
  "ccc.24_7service.label",
  "ccc.managesDataBreaches.text",
  "ccc.compensatesForIncomeLoss.text",
  "ccc.protectsClaims.text",
  "ccc.coversBreach.text",
];

const MainSection = () => {
  const navigate = useNavigate();
  const theme = useTheme() as Theme;

  const { t } = useTranslation();

  const classes = useStyles();

  return (
    <Box marginTop={5}>
      <Box>
        <Typography sx={{ fontWeight: 600, fontSize: { xs: 12, md: 16 } }}>
          {t("ccc.landingBody.text")}
        </Typography>
      </Box>
      <Box sx={{ marginTop: { xs: 4 } }}>
        <Typography sx={{ fontWeight: 600, fontSize: { xs: 12, md: 16 } }}>
          {t("ccc.landingBody2.text")}
        </Typography>
      </Box>
      <Box sx={{ marginTop: { xs: 2 } }}></Box>
      <Box sx={{ marginTop: { xs: 2 } }}>
        <Grid container columnSpacing={3}>
          <Grid item xs={12} lg={7}>
            <Typography sx={{ fontWeight: 600, fontSize: { xs: 12, md: 16 } }}>
              {t("ccc.landingSubtitle.label")}:
            </Typography>
            <ul className={classes.list}>
              {list.map((item) => (
                <li className={classes.listItem} key={item}>
                  {t(item)}
                </li>
              ))}
            </ul>
          </Grid>
          <Grid item xs={12} lg={5}>
            <DetailsVideo />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ marginTop: { xs: 2 } }}>
        <Typography sx={{ fontWeight: 600, fontSize: { xs: 12, md: 16 } }}>
          {t("ccc.landingBody3.text")}
        </Typography>
      </Box>
      <Box sx={{ marginTop: { xs: 2 } }}>
        <Typography sx={{ fontWeight: 600, fontSize: { xs: 12, md: 16 } }}>
          {t("ccc.cookieInfo")}
        </Typography>
      </Box>
      <Box>
        <Typography sx={{ fontWeight: 600, fontSize: { xs: 12, md: 16 } }}>
          {t("ccc.nextClickInfo")}
        </Typography>
      </Box>
      <Box display={"flex"} justifyContent="center">
        <Button
          onClick={() => navigate("/calculator")}
          color="primary"
          variant="contained"
          sx={{
            minWidth: { xs: 80, md: 120 },
            background: theme.palette.primary.main + " !important",
          }}
        >
          {t("ccc.next.label")}
        </Button>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    paddingLeft: "20px",
  },
  listItem: {
    fontWeight: 600,
    fontSize: 12,

    [theme.breakpoints.up("lg")]: {
      fontSize: 16,
    },
  },
}));

export default MainSection;
