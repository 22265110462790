import React from "react";

import { useTranslation } from "react-i18next";

import { Box } from "@mui/system";

import SectionTitle from "./SectionTitle";
import LogoInTitleSection from "components/LogoInTitleSection/LogoInTitleSection";
import MainSection from "./MainSection";

const Landing = () => {
  const { t } = useTranslation();
  return (
    <Box margin={"10px 0 0"}>
      <Box
        display={"flex"}
        justifyContent="space-between"
        alignItems={"center"}
      >
        <SectionTitle
          title={t("ccc.appTitle")}
          subtitle={t("ccc.appSubtitle")}
        />
        <LogoInTitleSection />
      </Box>
      <Box
        display={"flex"}
        justifyContent="space-between"
        alignItems={"center"}
      >
        <SectionTitle
          title={t("ccc.cyberCrime.label")}
          subtitle={t("ccc.topWordCorporateRisk.label")}
        />
      </Box>
      <MainSection />
    </Box>
  );
};

export default Landing;
