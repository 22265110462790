import axios from "axios";

const url = process.env.REACT_APP_BASE_URL;

class ApiService {
  public getTaxonomy = async () => {
    const res = await axios.get(`${url}/taxonomy`);
    try {
      return res.data;
    } catch (error) {
      return error?.response?.data;
    }
  };

  public executeCalculation = async (payload) => {
    const res = await axios.post(`${url}/calculations`, {
      ...payload,
      // CountryCode: 834,
      // SellerName: "Erste",
    });
    try {
      return res.data;
    } catch (error) {
      return error?.response?.data;
    }
  };

  public setOfferRequest = async (id) => {
    const res = await axios.patch(`${url}/calculations/${id}`);
    try {
      return res.data;
    } catch (error) {
      return error?.response?.data;
    }
  };

  public getCalculationResult = async (id) => {
    const res = await axios.get(`${url}/calculations/${id}`);
    try {
      return res.data;
    } catch (error) {
      return error?.response?.data;
    }
  };
}

export const apiService = new ApiService();
