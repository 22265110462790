import { Link, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import AppContext from "AppContext";
import { countryOptions, strings } from "library/shared/shared";
import { ECountryCode } from "library/utils/types";
import React, { useContext, useMemo } from "react";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    justifyContent: "end",
  },
  vmgLink: {
    width: "100%",
    fontWeight: "bold",
  },
  vmgLogo: {
    width: "115px",
    height: "40px",
    marginBottom: "5px",
  },
  grecoLogo: {
    width: "100px",
  },
}));

const LogoInTitleSection = () => {
  const { state } = useContext(AppContext);
  const classes = useStyles();
  const countryCode = state.CountryCode;
  const sellerName = state.SellerName;

  const logoSettings = useMemo(() => {
    switch (countryCode) {
      case ECountryCode.austria:
        if (sellerName === strings.GrECo_internal) {
          return {
            src: "/grecoLogo.png",
            logoClass: classes.grecoLogo,
          };
        } else {
          return {
            src: "/vmg.png",
            logoClass: classes.vmgLogo,
          };
        }
      case ECountryCode.slovakia:
        return {
          src: "/grecoLogo.png",
          logoClass: classes.grecoLogo,
        };
      case ECountryCode.romania:
        return {
          src: "/grecoLogo.png",
          logoClass: classes.grecoLogo,
        };
      case ECountryCode.estonia:
        return {
          src: "/grecoLogo.png",
          logoClass: classes.grecoLogo,
        };
      default:
        return {
          src: "/grecoLogo.png",
          logoClass: classes.grecoLogo,
        };
    }
  }, [classes.grecoLogo, classes.vmgLogo, countryCode, sellerName]);

  const isAustria =
    ECountryCode.austria === countryCode &&
    sellerName !== strings.GrECo_internal;

  return (
    <Box className={classes.container} marginTop={4}>
      <Box display={"flex"} alignItems="end" flexDirection={"column"}>
        <img
          src={`${process.env.PUBLIC_URL}${logoSettings.src}`}
          alt="logo"
          className={logoSettings.logoClass}
        />
        {isAustria && (
          <Link
            href="https://www.vmg.at/"
            target="_blank"
            color={"secondary"}
            className={classes.vmgLink}
            underline="none"
          >
            www.vmg.at
          </Link>
        )}
      </Box>
    </Box>
  );
};

export default LogoInTitleSection;
