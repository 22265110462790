export const riskStatChartOptions = {
  animation: {
    duration: 0,
  },
  events: [], //disable everything on hover
  indexAxis: "y" as const,
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  scales: {
    x: {
      ticks: {
        // Include a dollar sign in the ticks
        callback: function (value, index, ticks) {
          return value + "%";
        },
      },
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: "right" as const,
      display: false,
    },
    title: {
      display: false,
      position: "bottom" as const,
      // text: "Prozent ( % )",
    },
    tooltip: {
      display: true,
      callbacks: {
        label: function (context) {
          return `${context.raw}%`;
        },
      },
    },
    datalabels: {
      display: true,
      formatter: (value) => {
        return value + "%";
      },
    },
  },
};

export const riskStatChartLabels = (t) => [
  {
    label: `${t("ccc.fulfillAll.label")} 10`,
    value: "27",
  },
  {
    label: `${t("ccc.fulfill.label")} 7 - 9`,
    value: "54",
  },
  {
    label: `${t("ccc.fulfill.label")} 4 - 6`,
    value: "12",
  },
  {
    label: `${t("ccc.fulfill.label")} 0 - 3`,
    value: "7",
  },
];
