import React from "react";

const AppContext = React.createContext({
  taxonomy: [],
  calculationData: null,
  formSubmited: false,
  isSuccess: false,
  state: {
    CountryCode: 0,
    SellerName: "",
  },
  setFormSubmited: (value: boolean) => {
    throw new Error("setFormSubmited() not implemented");
  },

  setIsSuccess: (value: boolean) => {
    throw new Error("setIsSuccess() not implemented");
  },

  setCalculationData: (data: any) => {
    throw new Error("setCalculationData() not implemented");
  },
  setMainState: (data: any) => {
    throw new Error("setMainState() not implemented");
  },
});

export default AppContext;
