import React, { useMemo } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { riskStatChartLabels, riskStatChartOptions } from './riskChartConfig'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/styles'
import { Theme } from '@mui/material'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)
const isMobile = window.innerWidth < 500

const RiskStatisticChart = () => {
  const { t } = useTranslation()
  const theme = useTheme() as Theme
  const labels = useMemo(() => {
    return riskStatChartLabels(t)
  }, [t])

  const data = {
    labels: labels.map((item) => item.label),
    datasets: [
      {
        label: '',
        data: labels.map((item) => item.value),
        // borderColor: theme.palette.primary.dark,
        // backgroundColor: theme.palette.primary.main,

        borderColor: theme.palette.primary.main, //'#0069CA'
        backgroundColor: theme.palette.primary.main,
        datalabels: {
          color: 'white', //#dd8806 greco orange
          font: {
            size: 14,
            weight: 600,
          },
        },
      },
    ],
  }
  return (
    <Bar
      options={riskStatChartOptions}
      data={data}
      height={isMobile ? 140 : 60}
    />
  )
}

export default RiskStatisticChart
