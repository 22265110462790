import { Box, Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Title from 'components/Title/Title'
import React from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) => ({
  text: {
    fontWeight: '600',
    // marginTop: 10,
    [theme.breakpoints.up('md')]: {},
  },
}))

const Details = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <Box>
      <Title />
      <Box className={classes.text} mt={2}>
        {t('ccc.toolPurposeExplaned.label')}
      </Box>
      <Box className={classes.text}>{t('ccc.toolPurposeExplaned1.label')}</Box>
    </Box>
  )
}

export default Details
