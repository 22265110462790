import { Theme } from '@mui/material'
import TextField from '@mui/material/TextField'
import { makeStyles } from '@mui/styles'
import React from 'react'

type Props = {
  value: string
  onChange: any
  name: string
  disabled: boolean
  label: string
  errors?: any
  required?: boolean
  multiline?: boolean
  rows?: number
  [x: string]: any //rest props
}

const TextInputField = ({
  disabled,
  label,
  name,
  onChange,
  error = '',
  value,
  errors,
  required,
  ...props
}: Props) => {
  return (
    <TextField
      disabled={disabled}
      // sx={{
      //   "& label, input": {
      //     fontSize: { xs: "12px", lg: "16px" },
      //   },
      // }}
      InputProps={{
        autoComplete: 'on',
      }}
      name={name}
      fullWidth
      error={!!errors[name]}
      helperText={errors[name]}
      value={value || ''}
      onChange={onChange}
      id="standard-error-helper-text"
      label={label}
      size="small"
      required={required}
      variant="standard"
      {...props}
    />
  )
}

export default TextInputField
