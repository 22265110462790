import { Grid } from "@mui/material";
import React from "react";

const GridItem = ({ children }) => {
  return (
    <Grid item xs={12} lg={6}>
      {children}
    </Grid>
  );
};

export default GridItem;
