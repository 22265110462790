import * as React from "react";
// --- Fabric UI
import { Overlay, Spinner, SpinnerSize } from "office-ui-fabric-react";


export class GrecoSpinner extends React.PureComponent<{}> {
  public render(): JSX.Element {
    return (
      <Overlay>
        <div
          style={{
            position: "absolute",
            zIndex: 99999,
            background: "#333333",
            color: "white",
            left: "0",
            top: "0",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Spinner size={SpinnerSize.large} />
        </div>
      </Overlay>
    );
  }
}
