import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import { useTranslation } from 'react-i18next'
import { levelOfRiskLegendRows } from './levelOfRiskConfig'
import StyledTableCell from 'components/StyledTableCell/StyledTableCell'

const LeverOfRiskLegendTable = () => {
  const { t } = useTranslation()
  const rows = React.useMemo(() => {
    return levelOfRiskLegendRows(t)
  }, [t])
  return (
    <TableContainer
      sx={{
        wordBreak: 'break-word',
      }}
    >
      <Table aria-label="customized table 2">
        <TableHead>
          <TableRow sx={{ width: '100%' }}>
            <StyledTableCell>{t('ccc.legend.label')}</StyledTableCell>
            <StyledTableCell></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.title}>
              <TableCell
                sx={{
                  fontSize: { xs: '10px', lg: '16px' },
                  wordBreak: 'normal',
                }}
              >
                {row.titleFirst}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: { xs: '10px', lg: '16px' },
                  wordBreak: 'normal',
                }}
              >
                {row.title}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default LeverOfRiskLegendTable
