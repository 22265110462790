import PreviewLayout from 'components/Layout.tsx/PreviewLayout'

import Preview from 'components/Preview/Preview'

import Spinner from 'components/Spinner/Spinner'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { apiService } from 'services/api'

const PreviewPage = () => {
  const { id } = useParams()
  const [calculationResults, setCalculationResults] = useState<any>([])
  //89

  useEffect(() => {
    const fetchCalculationResult = async () => {
      try {
        const result = await apiService.getCalculationResult(id)
        setCalculationResults(result)
      } catch (error) {
        console.log(error, 'code: prew')
      }
    }
    fetchCalculationResult()
  }, [id, setCalculationResults])

  if (!calculationResults?.offerRequestedAt) {
    return <Spinner />
  }

  return (
    <PreviewLayout>
      <Preview calculationResults={calculationResults} />
    </PreviewLayout>
  )
}

export default PreviewPage
