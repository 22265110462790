import React from "react";
import { MessageBar, MessageBarType } from "office-ui-fabric-react/lib/MessageBar";
import { mergeStyles } from "@uifabric/styling";

interface IProps {
  message: string | JSX.Element;
  closeError: any;
}

const messageBarErrorStyle = mergeStyles({
  position: "fixed",
  top: "50px",
  width: "100%",
  right: "0",
  zIndex: 10
});

export class ErrorMessage extends React.PureComponent<IProps> {
  render() {
    return (
      <div className={messageBarErrorStyle}>
        <MessageBar
          messageBarType={MessageBarType.severeWarning}
          isMultiline={false}
          onDismiss={() => this.props.closeError()}
          dismissButtonAriaLabel="Close"
        >
          {this.props.message}
        </MessageBar>
      </div>
    );
  }
}
