import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    flexGrow: 1,
  },
  divider: {
    height: "2px",
    marginLeft: "10px",
    marginTop: "5px",
    background: theme.palette.grey[300],
    [theme.breakpoints.up("md")]: {
      marginLeft: "30px",
    },
  },
}));

const Divider = () => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Box className={classes.divider}></Box>
    </Box>
  );
};

export default Divider;
