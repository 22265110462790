import React from "react";
import { useEffect, useState } from "react";
import { apiService } from "services/api";
import AppContext from "./AppContext";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import { ECountryCode } from "library/utils/types";
import { strings } from "library/shared/shared";

const AppProvider = (props) => {
  const { t } = useTranslation();

  const { search, pathname } = window.location;

  const [taxonomy, setTaxonomy] = useState([]);
  const [calculationData, setCalData] = useState({});
  const [formSubmited, setFormIsSubmited] = useState(false);
  const [isSuccess, setIfIsSuccess] = useState(false);

  const [state, setState] = useState({
    CountryCode: ECountryCode.internal,
    SellerName: strings.GrECo_internal,
  });

  const setDefaultParams = () => {
    const settingsExist = sessionStorage.getItem("ccc-settings");
    const stringToDecode = queryString.parse(search)?.refvalue?.toString();
    if (!settingsExist || (stringToDecode && stringToDecode !== "")) {
      const paramsData = {} as any;
      let CountryCode = ECountryCode.internal; // TO BE DEFINED
      let SellerName = strings.GrECo_internal;
      if (stringToDecode) {
        const decodedString = atob(stringToDecode);
        const splitedString = decodedString.split("**");
        CountryCode = Number(splitedString[0]);
        SellerName = splitedString[1];
      }

      paramsData.CountryCode = CountryCode;
      paramsData.SellerName = SellerName;
      setState(paramsData);
      sessionStorage.setItem("ccc-settings", JSON.stringify(paramsData));
    } else {
      const paramsData = JSON.parse(sessionStorage.getItem("ccc-settings"));
      setState(paramsData);
    }
  };
  useEffect(() => {
    document.title = t("ccc.appTitle");
  }, []);

  useEffect(() => {
    if (!pathname.includes("calculation")) {
      setDefaultParams();
    }
  }, [pathname, search]);

  const fetchTaxonomy = async () => {
    const data = await apiService.getTaxonomy();
    setTaxonomy(data);
  };

  const setMainState = (data) => {
    setState(data);
  };

  useEffect(() => {
    fetchTaxonomy();
  }, []);

  const setFormSubmited = (value) => {
    setFormIsSubmited(value);
  };

  const setIsSuccess = (value) => {
    setIfIsSuccess(value);
  };

  const setCalculationData = (data) => {
    setCalData(data);
  };

  const providerValue = {
    taxonomy,
    formSubmited,
    isSuccess,
    calculationData,
    setFormSubmited,
    setCalculationData,
    setMainState,
    setIsSuccess,
    state,
  } as any;

  return (
    <AppContext.Provider value={providerValue}>
      {props.children}
    </AppContext.Provider>
  );
};

export default AppProvider;
