import React, { useMemo } from 'react'

import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Paper,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { getLegendConfig } from './levelOfRiskConfig'
import { IRiskData } from 'library/utils/types'

type Props = {
  riskData: IRiskData
}

const RiskChartLegend = ({ riskData }: Props) => {
  const { t } = useTranslation()

  const predictedDamageCrisisManagement =
    riskData?.predictedDamageCrisisManagement
  const predictedDamageBusinessInterruption =
    riskData?.predictedDamageBusinessInterruption
  const predictedDamageRecovery = riskData?.predictedDamageRecovery
  const predictedDamageRansom = riskData?.predictedDamageRansom
  const predictedDamageAll = riskData?.predictedDamageAll

  const config = useMemo(() => {
    return getLegendConfig({
      predictedDamageCrisisManagement,
      predictedDamageBusinessInterruption,
      predictedDamageRecovery,
      predictedDamageRansom,
      predictedDamageAll,
      t,
    })
  }, [
    predictedDamageAll,
    predictedDamageBusinessInterruption,
    predictedDamageCrisisManagement,
    predictedDamageRansom,
    predictedDamageRecovery,
    t,
  ])

  return (
    <Paper
      elevation={0}
      sx={{
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
      }}
    >
      <List
        sx={{
          padding: 0,
        }}
      >
        {config.map((item) => (
          <React.Fragment key={item.label}>
            <ListItem
              sx={{
                '& span': {
                  fontSize: { xs: '12px', lg: '16px' },
                },
              }}
            >
              <Box
                sx={{
                  background: item.backgroundColor,
                  marginRight: '5px',
                  height: { xs: '15px', lg: '20px' },
                  width: { xs: '15px', lg: '20px' },
                }}
              ></Box>
              <ListItemText primary={item.label} />
              <ListItemText
                sx={{
                  textAlign: 'end',
                }}
                primary={`${item.value} (${item.valuePercent})`}
              />
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
      </List>
    </Paper>
  )
}

export default RiskChartLegend
