import React from 'react'
import { useTranslation } from 'react-i18next'

import { useTheme } from '@emotion/react'
import { Box, Theme } from '@mui/material'

import FormTitle from 'components/form/FormTitle/FormTitle'
import RiskStatisticChart from './RiskStatisticChart'

const SecurityStatistic = () => {
  const theme = useTheme() as Theme
  const { t } = useTranslation()
  return (
    <Box marginTop={7}>
      <FormTitle>{t('ccc.itSecurityStatistic')}</FormTitle>
      <Box
        marginTop={3}
        sx={{
          padding: '16px',
          background: theme.palette.primary.light,
          color: theme.palette.common.black,
        }}
      >
        {t('ccc.compareWithOthers.label')}
      </Box>
      <Box marginTop={2}>
        <RiskStatisticChart />
      </Box>
    </Box>
  )
}

export default SecurityStatistic
