import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Theme } from "@mui/material";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    padding: 15,
    justifyContent: "center",
    alignItems: "center",
    maxWidth: 1600,
    margin: "0 auto",
    [theme.breakpoints.up("md")]: {
      padding: 100,
    },
  },
}));

const FormLayout = ({ children }) => {
  const classes = useStyles();
  return <Box className={classes.container}>{children}</Box>;
};

export default FormLayout;
