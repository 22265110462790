import { AuthProvider } from "@greco/services";

const graph = require("@microsoft/microsoft-graph-client");

export const MSGraphParams = {
  appId: process.env.REACT_APP_CLIENT_ID,
  redirectUri: window.location.origin,
  scopes: ["User.Read"],
  tenantId: process.env.REACT_APP_DIRECTORY_ID,
};

export const AzureGraphParams = {
  appId: process.env.REACT_APP_CLIENT_ID,
  redirectUri: window.location.origin,
  scopes: ["https://graph.windows.net/User.Read"],
  tenantId: process.env.REACT_APP_DIRECTORY_ID,
};

export const MSGraphAuth = new AuthProvider(MSGraphParams);

export const logout = () => {
  MSGraphAuth.logout();
};

async function getAuthenticatedClient() {
  let accessToken;
  await MSGraphAuth.getAccessToken().then((token) => {
    accessToken = token;
  });

  // Initialize Graph client
  const client = await graph.Client.init({
    // Use the provided access token to authenticate
    // requests
    authProvider: (done) => {
      done(null, accessToken.accessToken);
    },
  });

  return client;
}

export async function getUserDetails(userId) {
  const client = await getAuthenticatedClient();

  try {
    const user = await client.api(userId ? "/users/" + userId : "/me").get();
    return user;
  } catch (error) {
    // TODO log error
    return undefined;
  }
}
