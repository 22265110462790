import React from "react";
import LandingLayout from "components/Layout.tsx/LandingLayout/LandingLayout";
import Landing from "components/Landing/Landing";

const LandingPage = () => {
  return (
    <LandingLayout>
      <Landing />
    </LandingLayout>
  );
};

export default LandingPage;
