import { Box, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ECountryValue } from "library/utils/types";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    marginTop: 20,
  },
  legalWrap: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },

  legalLink: {
    textDecoration: "none",
    color: "inherit",
  },
  networksWrap: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 10,
  },
}));

const LegalSection = () => {
  const { t, i18n } = useTranslation();

  const classes = useStyles();

  const settings = useMemo(() => {
    switch (i18n.language) {
      case ECountryValue.austria:
        return {
          legalDisclaimer:
            "https://site.greco.services/at/legal-disclaimer.html",
          impressum: "https://site.greco.services/at/impressum.html",
          dataProtection:
            "https://site.greco.services/at/ATGI_data_protection_declaration_clients.pdf",
        };
      case ECountryValue.slovakia:
        return {
          legalDisclaimer:
            "https://site.greco.services/sk/legal-disclaimer.html",
          impressum: "https://site.greco.services/sk/impressum.html",
          dataProtection:
            "https://site.greco.services/sk/SKG_data_protection_declaration_clients.pdf",
        };
      case ECountryValue.romania:
        return {
          legalDisclaimer:
            "https://site.greco.services/ro/legal-disclaimer.html",
          impressum: "https://site.greco.services/ro/impressum.html",
          dataProtection:
            "https://site.greco.services/ro/ROG_data_protection_declaration_clients.pdf",
        };
      case ECountryValue.estonia:
        return {
          legalDisclaimer:
            "https://site.greco.services/ee/legal-disclaimer.html",
          impressum: "https://site.greco.services/ee/impressum.html",
          dataProtection:
            "https://site.greco.services/ee/GJEE_data_protection_declaration.pdf",
        };
      default:
        return {
          legalDisclaimer:
            "https://site.greco.services/en/legal-disclaimer.html",
          impressum: "https://site.greco.services/en/impressum.html",
          dataProtection:
            "https://site.greco.services/en/ATGI_data_protection_declaration_clients_EN.pdf",
        };
    }
  }, [i18n.language]);

  return (
    <Box className={classes.container}>
      <Box className={classes.legalWrap}>
        <Typography fontSize="14px" color={"common.black"} fontWeight="600">
          &copy; GrECo 2022
        </Typography>
        <Typography sx={{ margin: "0 5px" }}>|</Typography>
        <Typography
          color={"primary"}
          fontWeight="600"
          fontSize="14px"
          textTransform={"uppercase"}
        >
          <a
            href={settings.legalDisclaimer}
            target="_blank"
            className={classes.legalLink}
            rel="noreferrer"
          >
            {t("ccc.legalDisclaimer.label")}
          </a>
        </Typography>
        <Typography sx={{ margin: "0 5px" }}>|</Typography>
        <Typography
          color={"primary"}
          fontWeight="600"
          fontSize="14px"
          textTransform={"uppercase"}
        >
          <a
            href={settings.impressum}
            target="_blank"
            className={classes.legalLink}
            rel="noreferrer"
          >
            {t("ccc.impressum.label")}
          </a>
        </Typography>
        <Typography sx={{ margin: "0 5px" }}>|</Typography>
        <Typography
          color={"primary"}
          fontWeight="600"
          fontSize="14px"
          textTransform={"uppercase"}
        >
          <a
            href={settings.dataProtection}
            target="_blank"
            className={classes.legalLink}
            rel="noreferrer"
          >
            {t("ccc.dataProtection")}
          </a>
        </Typography>
      </Box>
      <Box className={classes.networksWrap}>
        <Box marginRight={"5px"}>
          <a
            href="https://site.facebook.com/greco.services"
            target="_blank"
            rel="noreferrer"
          >
            <img src="/social-facebook.png" title="facebook" alt="facebook" />
          </a>
        </Box>
        <Box marginRight={"5px"}>
          <a
            href="https://site.xing.com/companies/grecointernationalag?sc_o=da980_e"
            target="_blank"
            rel="noreferrer"
          >
            <img src="/social-xing.png" title="xing" alt="xing" />
          </a>
        </Box>
        <Box marginRight={"5px"}>
          <a
            href="https://site.linkedin.com/company/19172553/"
            target="_blank"
            rel="noreferrer"
          >
            <img src="/social-linkedin.png" title="linkedin" alt="linkedin" />
          </a>
        </Box>
        <Box marginRight={"5px"}>
          <a
            href="https://site.instagram.com/greco.services"
            target="_blank"
            rel="noreferrer"
          >
            <img src="/insta.png" title="instagram" alt="instagram" />
          </a>
        </Box>
      </Box>
    </Box>
  );
};

export default LegalSection;
