import { Grid } from '@mui/material'
import { Box } from '@mui/system'
import AppContext from 'AppContext'
import ComboBoxField from 'components/controls/ComboBoxField'
import TextInputField from 'components/controls/TextInputField'
import GridContainer from 'components/form/GridContainer/GridContainer'
import GridItem from 'components/form/GridItem/GridItem'
import GridWrap from 'components/form/GridWrap/GridWrap'
import { FormikProps, FormikValues, useFormikContext } from 'formik'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import useTaxonomyOptions from './useTaxonomyOptions'

const CalculateCyberRiskFormItems = () => {
  const { t } = useTranslation()
  const { errors, setFieldValue } = useFormikContext() as FormikProps<
    FormikValues
  >

  const {
    sicOptions,
    revenueOptions,
    numberOfEmployeesOptions,
    numberOfClientsOptions,
    numberOfDaysOptions,
    numberOfServersOptions,
  } = useTaxonomyOptions()

  return (
    <GridWrap>
      <GridContainer>
        <GridItem>
          <ComboBoxField
            setFieldValue={setFieldValue}
            required
            errors={errors}
            options={sicOptions}
            name={'SicCodeChoiceCode'}
            disabled={false}
            label={t('ccc.sicCodeChoiceCode.label')}
          />
        </GridItem>
        <GridItem>
          <ComboBoxField
            setFieldValue={setFieldValue}
            required
            errors={errors}
            options={revenueOptions}
            name={'RevenueRangeEurCode'}
            disabled={false}
            label={t('ccc.revenueRangeEurCode.label')}
          />
        </GridItem>
        <GridItem>
          <ComboBoxField
            setFieldValue={setFieldValue}
            required
            errors={errors}
            options={numberOfEmployeesOptions}
            name={'NumberOfEmployeesRangeCode'}
            disabled={false}
            label={t('ccc.numberOfEmployeesRangeCode.label')}
          />
        </GridItem>
        <GridItem>
          <ComboBoxField
            setFieldValue={setFieldValue}
            required
            errors={errors}
            options={numberOfServersOptions}
            name={'NumberOfServersRangeCode'}
            disabled={false}
            label={t('ccc.numberOfServersRangeCode.label')}
          />
        </GridItem>
        <GridItem>
          <ComboBoxField
            setFieldValue={setFieldValue}
            required
            errors={errors}
            options={numberOfClientsOptions}
            name={'NumberOfClientsRangeCode'}
            disabled={false}
            label={t('ccc.numberOfClientsRangeCode.label')}
          />
        </GridItem>

        <GridItem>
          <ComboBoxField
            setFieldValue={setFieldValue}
            required
            errors={errors}
            options={numberOfDaysOptions}
            name={'NumberOfDaysChoiceCode'}
            disabled={false}
            label={t('ccc.operationsUtilization.label')}
          />
        </GridItem>
      </GridContainer>
    </GridWrap>
  )
}

export default CalculateCyberRiskFormItems
