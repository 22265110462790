import React, { useMemo } from 'react'
import { Box } from '@mui/system'
import FormTitle from 'components/form/FormTitle/FormTitle'
import { useTranslation } from 'react-i18next'
import PreviewResponseItem from './PreviewResponseItem'
import useTaxonomyOptions from 'components/Main/FormSection/FormItems/CalculateCyberRisk/useTaxonomyOptions'

type Props = {
  title?: string
  calculationInputData: any
  fieldsToShow: string[]
  offerRequestedAt?: string
  valuesFromTaxonomy?: boolean
}

const PreviewResponses = ({
  title,
  calculationInputData,
  fieldsToShow,
  offerRequestedAt,
  valuesFromTaxonomy,
}: Props) => {
  const { t } = useTranslation()
  const taxonomyOptions = useTaxonomyOptions()

  return (
    <Box marginTop={4}>
      {title && <FormTitle>{t(title)}</FormTitle>}
      {offerRequestedAt && (
        <PreviewResponseItem
          label={`ccc.offerRequestedAt.label`}
          value={offerRequestedAt}
        />
      )}
      {Object.keys(calculationInputData)
        .filter((item) => fieldsToShow.includes(item))
        .map((item) => {
          let value = calculationInputData[item]
          if (value.toString() === 'true') {
            value = t('ccc.yes.label')
          }
          if (value.toString() === 'false') {
            value = t('ccc.no.label')
          }
          if (valuesFromTaxonomy) {
            let options = []
            if (item === 'sicCodeChoiceCode') {
              options = taxonomyOptions.sicOptions
            }
            if (item === 'revenueRangeEurCode') {
              options = taxonomyOptions.revenueOptions
            }
            if (item === 'numberOfEmployeesRangeCode') {
              options = taxonomyOptions.numberOfEmployeesOptions
            }
            if (item === 'numberOfServersRangeCode') {
              options = taxonomyOptions.numberOfServersOptions
            }
            if (item === 'numberOfClientsRangeCode') {
              options = taxonomyOptions.numberOfClientsOptions
            }
            if (item === 'numberOfDaysChoiceCode') {
              options = taxonomyOptions.numberOfDaysOptions
            }
            value = options.find((item) => item.id === value)?.label
          }

          return (
            <PreviewResponseItem
              key={item}
              label={`ccc.${item}.label`}
              value={value}
            />
          )
        })}
    </Box>
  )
}

export default PreviewResponses
