import React from "react";
import ReactDOM, { render } from "react-dom";
import "./index.css";

import "./i18n";

import reportWebVitals from "./reportWebVitals";
import App from "./App";
import { StyledEngineProvider } from "@mui/material/styles";
import Axios from "axios";
import { MSGraphAuth } from "services/graphService";
import { AccountInfo } from "@azure/msal-browser";

const Params = {
  appId: process.env.REACT_APP_CLIENT_ID,
  redirectUri: window.location.origin,
  scopes: [`${process.env.REACT_APP_CLIENT_ID}/.default`],
  tenantId: process.env.REACT_APP_DIRECTORY_ID,
};

Axios.interceptors.request.use(async (config) => {
  const pathname = window.location.pathname;
  if (pathname.includes("calculation")) {
    let authResponse = await MSGraphAuth.getAccessToken(Params);
    if (!authResponse) return config;

    config.headers.Authorization = `Bearer ${authResponse.accessToken}`;
  }

  return config;
});

const startApplication = () => {
  ReactDOM.render(
    <React.StrictMode>
      <StyledEngineProvider injectFirst>
        <App />
      </StyledEngineProvider>
    </React.StrictMode>,
    document.getElementById("root")
  );
};

MSGraphAuth.accountObj
  .then((accountInfo: AccountInfo | null) => {
    const pathname = window.location.pathname;
    const loginNeeded = pathname.includes("calculation");

    if (loginNeeded && !accountInfo) {
      MSGraphAuth.login();
    } else {
      startApplication();
    }
  })
  .catch((error) => {
    if (error === "There isn't any account detected.") {
      const pathname = window.location.pathname;
      const loginNeeded = pathname.includes("calculation");
      if (loginNeeded) {
        MSGraphAuth.login();
      } else {
        startApplication();
      }
    } else {
      console.log(error);
    }
  });

// ReactDOM.render(
//   <React.StrictMode>
//     <StyledEngineProvider injectFirst>
//       <App />
//     </StyledEngineProvider>
//   </React.StrictMode>,
//   document.getElementById("root")
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
