import * as React from 'react';
import './GrecoPanel.scss';
// -- Fabric UI
import { Panel, PanelType } from 'office-ui-fabric-react';

// -- Components
import { Help } from './Help/Help';
import { Notification } from './Notification/Notification';
import { Settings } from './Settings/Settings';
import { UserSettings } from './UserSettings/UserSettings';
import { IGrecoPanelStrings } from './IGrecoPanelStrings';
import { PanelType as GrecoPanelType } from './PanelType';
import { INotificationStrings } from './Notification/INotificationStrings';
import { ISettingsStrings } from './Settings/ISettingsStrings';
import { IUserSettingsStrings } from './UserSettings/IUserSettingsStrings';

interface IProps {
  panelIsOpen: any;
  onDismiss: any;
  panelType: GrecoPanelType | null;
  userData: any;
  logout: any;
  tooltipStatusChange?: any;
  tooltipStatus?: any;
  darkMode?: any;
  headerConfig: any;
  panelStrings?: IGrecoPanelStrings;
  showErrorAndWarningMessages?: () => void;
  messagesCount?: number;
}

export class GrecoPanel extends React.PureComponent<IProps, {}> {
  panelStyle = {
    height: 'calc(100vh - 50px)',
    top: '50px',
  };

  public render(): JSX.Element {
    return (
      <Panel
        style={
          this.props.panelType !== GrecoPanelType.AppLauncher
            ? this.panelStyle
            : {}
        }
        className="remove-shadow"
        isBlocking={false}
        type={
          this.props.panelType === GrecoPanelType.AppLauncher
            ? PanelType.customNear
            : PanelType.smallFixedFar
        }
        isOpen={this.props.panelIsOpen}
        isLightDismiss={true}
        onDismiss={this.props.onDismiss}
        isHiddenOnDismiss={false}
        hasCloseButton={this.props.panelType !== GrecoPanelType.AppLauncher}
        customWidth="320px"
      >
        {this.props.panelType !== GrecoPanelType.AppLauncher && (
          <h2 className="panel-title">
            {(this.props.panelStrings && this.props.panelStrings.panelTitle) ||
              this.props.panelType}
          </h2>
        )}
        {this.renderComponent()}
      </Panel>
    );
  }

  renderComponent = () => {
    if (this.props.panelType === GrecoPanelType.Notifications) {
      return (
        <Notification
          strings={this.props.panelStrings as INotificationStrings}
          messages={this.props.showErrorAndWarningMessages}
          messagesCount={this.props.messagesCount}
        />
      );
    } else if (this.props.panelType === GrecoPanelType.UserSettings) {
      return (
        <UserSettings
          userData={this.props.userData}
          logout={this.props.logout}
          strings={this.props.panelStrings as IUserSettingsStrings}
        />
      );
    } else if (this.props.panelType === GrecoPanelType.Help) {
      return <Help />;
    } else if (this.props.panelType === GrecoPanelType.Settings) {
      return (
        <Settings
          darkMode={this.props.darkMode}
          tooltipsStatus={this.props.tooltipStatus}
          tooltipStatusChange={this.props.tooltipStatusChange}
          strings={this.props.panelStrings as ISettingsStrings}
        />
      );
    }
  };
}
