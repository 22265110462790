import { Box, Grid } from '@mui/material'
import FormTitle from 'components/form/FormTitle/FormTitle'
import React from 'react'
import LeverOfRiskLegendTable from './LeverOfRiskLegendTable'
import RiskChart from './RiskChart'
import RiskChartLegend from './RiskChartLegend'
import { useTranslation } from 'react-i18next'
import { IRiskData } from 'library/utils/types'

type Props = {
  riskData: IRiskData
}

const LevelOfRisk = ({ riskData }: Props) => {
  const { t } = useTranslation()
  return (
    <Box marginTop={7}>
      <FormTitle>{t('ccc.calculateValueOfRisk.label')}</FormTitle>
      <Box marginTop={2}>
        <Grid
          container
          columnSpacing={{ xs: 1, sm: 2, md: 5 }}
          rowSpacing={{ xs: 1 }}
        >
          <Grid item xs={12} md={6}>
            <RiskChartLegend riskData={riskData} />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              paddingTop: '0 !important',
            }}
          >
            <RiskChart riskData={riskData} />
          </Grid>
        </Grid>
      </Box>
      <Box marginTop={7}>
        <LeverOfRiskLegendTable />
      </Box>
    </Box>
  )
}

export default LevelOfRisk
